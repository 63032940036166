import { create } from 'zustand'
import { InvoiceFilter } from '../interfaces/invoice-filter'

interface InvoiceFilterState {
  filter: InvoiceFilter;
  actions: {
    setCustomers: (customers: string[]) => void;
    setStatus: (status: string[]) => void;
    setDateRange: (dateRange: [Date | null, Date | null]) => void;
  }
}

const useInvoiceFilterStore = create<InvoiceFilterState>((set) => ({
  filter: {
    customers: [],
    status: [],
    dateRange: [null, null],
  },
  actions: {
    setCustomers: (customers) => set((state) => ({ filter: { ...state.filter, customers } })),
    setStatus: (status) => set((state) => ({ filter: { ...state.filter, status } })),
    setDateRange: (dateRange) => set((state) => ({ filter: { ...state.filter, dateRange } })),
  },
}))

export const useInvoiceFilter = () => useInvoiceFilterStore((state) => state.filter)
export const useInvoiceFilterActions = () => useInvoiceFilterStore((state) => state.actions)
