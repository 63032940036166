import { useFormContext } from 'react-hook-form'
import {
  Text, Card, NumberInput,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { BiTrashAlt } from 'react-icons/bi'
import PriceTierFromToInput from './price-tier-from-to-input'
import { UpsertExtendedPlanAttributes } from '../../../interfaces'
import DecimalInput from '../../../shared/components/form/decimal-input'

interface Props {
  metricIndex: number,
  index: number,
  remove: (index: number) => void,
}

export default function BillingPackageForm({ metricIndex, index, remove }: Props) {
  const { t } = useTranslation(['common', 'priceTier'])

  const {
    setValue, getValues, formState: { errors },
  } = useFormContext<UpsertExtendedPlanAttributes>()

  return (
    <Card withBorder padding="md" radius="md">
      <div className="flex mb-4 justify-between">
        <div className="flex flex-row">
          <Text className="text-gray-700" size="sm">
            {`${t('priceTier:billing-type')}:`}
          </Text>
          <Text className="text-gray-800 ml-1" fw={600} size="sm">
            {t('priceTier:billing-type-package')}
          </Text>
        </div>
        <BiTrashAlt
          className="cursor-pointer"
          onClick={() => remove(index)}
          size={22}
        />
      </div>

      <PriceTierFromToInput metricIndex={metricIndex} index={index} />

      <div className="flex flex-row gap-2 items-center mt-4">
        <Text className="text-gray-700" size="sm">{`${t('priceTier:package-size')}:`}</Text>
        <NumberInput
          className="w-32"
          min={1}
          placeholder={t('common:form-placeholder:zero') as string}
          error={errors.metrics?.[metricIndex]?.priceTiers?.[index]?.packageSize && errors.metrics?.[metricIndex]?.priceTiers?.[index]?.message}
          value={getValues(`metrics.${metricIndex}.priceTiers.${index}.packageSize`) || 1}
          onChange={(value: number | string) => setValue(`metrics.${metricIndex}.priceTiers.${index}.packageSize`, Number(value) as number)}
          required
        />
        <Text className="text-gray-700 ml-2" size="sm">{`${t('priceTier:price-of-package')}:`}</Text>
        <DecimalInput
          className="w-40"
          placeholder={t('common:form-placeholder:zero') as string}
          error={errors.metrics?.[metricIndex]?.priceTiers?.[index]?.price && errors.metrics?.[metricIndex]?.priceTiers?.[index]?.message}
          value={getValues(`metrics.${metricIndex}.priceTiers.${index}.price`)}
          onChange={(value: string) => setValue(`metrics.${metricIndex}.priceTiers.${index}.price`, value)}
          required
        />
      </div>
    </Card>
  )
}
