import { useTranslation } from 'react-i18next'
import { Button } from '@mantine/core'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import Modal from '../../../shared/components/general/modal'
import UpsertContractAttributes from '../../../interfaces/contract/upsert-contract-attributes'
import { UseCreateContract } from '../queries'
import ContractForm from './contract-form'

interface Props {
  opened: boolean;
  onClose: () => void;
}

export default function CreateContractModal({ opened, onClose }: Props) {
  const { t } = useTranslation('customer')
  const { id: customerId } = useParams()

  const useFormMethods = useForm<UpsertContractAttributes>({
    mode: 'onTouched',
    defaultValues: {
      isAutoRenewalEnabled: false,
    },
  })

  const {
    handleSubmit, reset, formState: { isValid }, watch,
  } = useFormMethods

  const formValues = watch()

  const isSubmitDisabled = useMemo(() => {
    const isPlansValid = isValid && formValues.plans && formValues.plans.length
    const isEndDateValid = (formValues.isAutoRenewalEnabled && formValues.endDate === null) || (!formValues.isAutoRenewalEnabled && formValues.endDate !== null)
    return isEndDateValid && isPlansValid
  }, [isValid, formValues])

  const handleCreatePaymentAccountSuccess = () => {
    onClose()
    reset()
  }

  const { mutate, isLoading } = UseCreateContract({ onSuccess: handleCreatePaymentAccountSuccess })

  return (
    <Modal size="xl" opened={opened} onClose={onClose} title={t('customer:add-contract')}>
      <FormProvider {...useFormMethods}>
        <div className="flex flex-col">
          <div className="w-[calc(100%-20px)]">
            <form
              onSubmit={handleSubmit((upsertContractAttributes) => mutate({ ...upsertContractAttributes, customerId: customerId! }))}
              className="flex flex-col w-full"
            >
              <ContractForm />

              <div className="w-full h-15 bg-white bottom-0 -mb-4 sticky">
                <Button
                  className="w-50 mt-3"
                  color="violet"
                  type="submit"
                  disabled={!isSubmitDisabled}
                  loading={isLoading}
                >
                  {t('common:save')}
                </Button>
              </div>

            </form>
          </div>
        </div>
      </FormProvider>
    </Modal>
  )
}
