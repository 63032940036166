import React from 'react'
import { TextInput, TextInputProps } from '@mantine/core'
import { FiDollarSign } from 'react-icons/fi'
import { MdOutlinePercent } from 'react-icons/md'

type Props = Omit<TextInputProps, 'onChange' | 'value'> & { decimalInputType?: 'currency' | 'percentage', onChange: (value: number) => void, value: number }

const CentsInput = React.forwardRef(({
  onChange, value, decimalInputType = 'currency', ...props
}: Props, ref: any) => {
  const currencyFormatter = (currencyValue: number | undefined) => {
    const displayValue = ((currencyValue || 0) / 100).toFixed(2)
    let formattedValue = displayValue.toString().replace(/\D/g, '')
    formattedValue = formattedValue.replace(/(\d)(\d{2})$/, '$1,$2')
    formattedValue = formattedValue.replace(/(?=(\d{3})+(\D))\B/g, '.')

    return formattedValue
  }

  const parserFormatter = (currencyValue: string) => {
    const cleanedValue = currencyValue.replace(/[.,\s]/g, '')
    const parsedValue = parseInt(cleanedValue, 10)

    return Number.isNaN(parsedValue) ? 0 : parsedValue
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parserFormatter(e.target.value)
    if (parsedValue.toString().length <= 9) {
      onChange(parsedValue)
    }
  }

  return (
    <TextInput
      leftSection={decimalInputType === 'currency' ? <FiDollarSign /> : <MdOutlinePercent />}
      value={currencyFormatter(value)}
      onChange={(e) => handleChange(e)}
      ref={ref}
      {...props}
    />
  )
})

export default CentsInput
