import { MatchKey } from '../../../interfaces/queries/match-key'
import Invoice from '../../../models/invoice'

const partialMatchKeys : MatchKey<Invoice>[] = ['id', 'customer.name']
const fullMatchKeys : MatchKey<Invoice>[] = []
const indexKey = 'id' as keyof Invoice

const tableInvoiceUtils = {
  partialMatchKeys,
  fullMatchKeys,
  indexKey,
}

export default tableInvoiceUtils
