import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import UpsertContractAttributes from '../../../interfaces/contract/upsert-contract-attributes'
import i18n from '../../../config/i18n'
import { ExtendedContract } from '../../../models'

const upsertContractAttributesToCreateContractRequest = (upsertContractAttributes: UpsertContractAttributes) => {
  const planIds = upsertContractAttributes.plans.map((plan) => plan.id)

  const createCustomerRequest = {
    startDate: dayjs(upsertContractAttributes.startDate).format('YYYY-MM-DD'),
    endDate: upsertContractAttributes.endDate ? dayjs(upsertContractAttributes.endDate).format('YYYY-MM-DD') : null,
    billingEndDay: upsertContractAttributes.billingEndDay,
    dueDateDay: upsertContractAttributes.dueDateDay,
    contractNonBusinessDayHandling: upsertContractAttributes.contractNonBusinessDayHandling,
    isAutoRenewalEnabled: upsertContractAttributes.isAutoRenewalEnabled,
    customerId: upsertContractAttributes.customerId,
    paymentAccountId: upsertContractAttributes.paymentAccountId,
    planIds,
  }

  return createCustomerRequest
}

const upsertContractAttributesToUpdateContractRequest = (upsertContractAttributes: UpsertContractAttributes) => {
  const { id } = upsertContractAttributes
  if (!id) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw toast.error(i18n.t('messages:error.default'))
  }

  const planIds = upsertContractAttributes.plans.map((plan) => plan.id)

  const updateContractRequest = {
    id,
    startDate: dayjs(upsertContractAttributes.startDate).format('YYYY-MM-DD'),
    endDate: upsertContractAttributes.endDate ? dayjs(upsertContractAttributes.endDate).format('YYYY-MM-DD') : null,
    billingEndDay: upsertContractAttributes.billingEndDay,
    dueDateDay: upsertContractAttributes.dueDateDay,
    contractNonBusinessDayHandling: upsertContractAttributes.contractNonBusinessDayHandling,
    isAutoRenewalEnabled: upsertContractAttributes.isAutoRenewalEnabled,
    customerId: upsertContractAttributes.customerId,
    paymentAccountId: upsertContractAttributes.paymentAccountId,
    planIds,
  }

  return updateContractRequest
}

const extendedContractToUpsertContractAttributes = (extendedContract: ExtendedContract) => {
  const upsertContractAttributes = {
    id: extendedContract.id,
    startDate: dayjs(extendedContract.startDate).toDate(),
    endDate: extendedContract.endDate ? dayjs(extendedContract.endDate).toDate() : null,
    billingEndDay: extendedContract.billingEndDay,
    dueDateDay: extendedContract.dueDateDay,
    contractNonBusinessDayHandling: extendedContract.contractNonBusinessDayHandling,
    isAutoRenewalEnabled: extendedContract.isAutoRenewalEnabled,
    customerId: extendedContract.customerId,
    paymentAccountId: extendedContract.paymentAccount.id,
    plans: extendedContract.plans,
  }

  return upsertContractAttributes
}

const upsertContractUtils = {
  upsertContractAttributesToCreateContractRequest,
  upsertContractAttributesToUpdateContractRequest,
  extendedContractToUpsertContractAttributes,
}

export default upsertContractUtils
