import { AxiosError } from 'axios'
import { QueryCache, QueryClient } from 'react-query'
import i18n from 'i18next'
import ErrorResponse from '../libs/api/responses/error-response'
import makeError from '../features/error/helpers/make-error'
import ErrorType from '../features/error/enum/error-type'
import getErrorMessage from '../features/error/helpers/get-error-message'

type StatusCodeList = (number | undefined)[]

export const logoutStatusCodes: StatusCodeList = [401, 403]

const didUserLoggedout = (error: AxiosError<ErrorResponse>) => {
  const statusCode = error.response?.status

  if (logoutStatusCodes.includes(statusCode)) {
    makeError({
      message: i18n.t('messages:error.unauthorized'),
      type: ErrorType.Loggout,
    })
    return true
  }
  return false
}

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      const errorResponse = error as AxiosError<ErrorResponse>
      const errorData = errorResponse.response?.data

      if (didUserLoggedout(errorResponse)) return

      if (query.state.data !== undefined) {
        makeError({
          message: i18n.t(getErrorMessage(errorData?.code)),
          type: ErrorType.Notification,
        })
      }

      makeError({
        message: i18n.t(getErrorMessage(errorData?.code)),
        type: ErrorType.Fallback,
        stack: errorResponse.stack,
      })
    },
  }),

  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        const errorResponse = error as AxiosError<ErrorResponse>
        const statusCode = errorResponse?.response?.status

        if (logoutStatusCodes.includes(statusCode)) {
          return false
        }

        return failureCount < 3
      },
    },
    mutations: {
      onError: (error) => {
        const errorResponse = error as AxiosError<ErrorResponse>
        const errorData = errorResponse.response?.data

        if (didUserLoggedout(errorResponse)) return

        makeError({
          message: i18n.t(getErrorMessage(errorData?.code)),
          type: ErrorType.Notification,
        })
      },
    },
  },
})

export default queryClient
