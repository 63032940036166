import { TextInput } from '@mantine/core'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { emailValidationRegex } from '../../../shared/utils/regex'
import UpsertPaymentAccountAttributes from '../../../interfaces/payment-account/payment-account-attributes'
import TaxIdInput from '../../../shared/components/form/tax-id-input'

export default function PaymentAccountForm() {
  const { t } = useTranslation(['customer', 'common'])

  const {
    register,
    formState: { errors },
  } = useFormContext<UpsertPaymentAccountAttributes>()

  return (
    <div className="flex flex-col w-full gap-2">
      <TaxIdInput
        required
        label={t('customer:tax-id') as string}
        placeholder={t('customer:tax-id') as string}
        error={errors.taxId && errors.taxId.message as string}
        {...register('taxId', {
          required: { value: true, message: t('common:form-error:required-field') },
        })}
      />

      <TextInput
        required
        label={t('customer:business-name') as string}
        placeholder={t('customer:business-name') as string}
        error={errors.businessName && errors.businessName.message as string}
        {...register('businessName', {
          required: { value: true, message: t('common:form-error:required-field') },
        })}
      />

      <TextInput
        label={t('customer:trade-name') as string}
        placeholder={t('customer:trade-name') as string}
        error={errors.tradeName && errors.tradeName.message as string}
        {...register('tradeName')}
      />

      <TextInput
        label={t('user:email') as string}
        placeholder={t('user:email') as string}
        error={errors.email && errors.email.message as string}
        {...register('email', {
          validate: {
            validEmail: (email) => email === '' || email === null || emailValidationRegex.test(email || '') || t('common:form-error:invalid-email') as string,
          },
        })}
      />

    </div>
  )
}
