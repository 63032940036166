import { modals } from '@mantine/modals'
import { useTranslation } from 'react-i18next'
import { ExtendedPlan } from '../../../../models'
import DeleteCard from '../../../../shared/components/alert/delete-card'
import { useDeletePlan } from '../../queries'
import { useDeletePlanErrorAlert } from './delete-plan-error-alert'

const useDeletePlanHandler = () => {
  const { t } = useTranslation()

  const { mutate } = useDeletePlan()

  const deletePlanErrorAlert = useDeletePlanErrorAlert()

  return (plan: ExtendedPlan) => {
    modals.open({
      withCloseButton: false,
      children: (
        <DeleteCard
          title={t('plan:delete-alert:title')}
          subtitle={t('plan:delete-alert:subtitle')}
          deletedItemName={t('plan:delete-alert:target-plan', {
            plan: plan.name,
          })}
          conditions={[t('plan:delete-alert:conditions')]}
          consequences={[
            { variant: 'danger', text: t('plan:delete-alert:consequences:delete-danger') },
            { variant: 'secure', text: t('plan:delete-alert:consequences:resources-check') },
            { variant: 'secure', text: t('plan:delete-alert:consequences:invoices-check') },
          ]}
          onDelete={() => {
            mutate(plan.id, {
              onSuccess: () => modals.closeAll(),
              onError: () => deletePlanErrorAlert(),
            })
          }}
          onCancel={() => modals.closeAll()}
        />
      ),
    })
  }
}

export default useDeletePlanHandler
