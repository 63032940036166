import { images } from '../../../shared/utils/public'
import SidebarFooter from './sidebar-footer'
import SidebarMenu from './sidebar-menu'

export default function Sidebar() {
  return (
    <div
      className="flex h-full flex-col w-48 bg-white p-5 fixed"
    >
      <div className="mb-10">
        <img
          src={images.airaLogo.src}
          alt={images.airaLogo.alt}
          className="w-1/2"
        />
      </div>
      <div className="flex-grow">
        <SidebarMenu />
      </div>
      <div>
        <SidebarFooter />
      </div>
    </div>
  )
}
