import React from 'react'
import { NumberInput, NumberInputProps } from '@mantine/core'
import { FiDollarSign } from 'react-icons/fi'
import { MdOutlinePercent } from 'react-icons/md'

type Props = Omit<NumberInputProps, 'onChange' | 'value'> & { decimalInputType?: 'currency' | 'percentage', onChange: (value: string) => void, value: string }

const DecimalInput = React.forwardRef(({
  onChange, value, decimalInputType = 'currency', ...props
}: Props, ref: any) => {
  const handleChange = (newValue: string | number) => {
    let updatedValue = '0'

    if (newValue !== '' && newValue !== '.') updatedValue = newValue.toString()
    if (newValue === '.') updatedValue = '0.0'

    onChange(updatedValue)
  }

  const formatedValue = () => {
    const parts = value.split('.')
    if (parts.length === 2) {
      const integerPart = parts[0]
      const decimalPart = parts[1].replace(/0+$/, '')
      const formattedValue = decimalPart ? `${integerPart}.${decimalPart}` : integerPart
      return formattedValue
    }
    return value
  }

  return (
    <NumberInput
      leftSection={decimalInputType === 'currency' ? <FiDollarSign /> : <MdOutlinePercent />}
      value={formatedValue()}
      onChange={handleChange}
      min={0}
      decimalScale={6}
      allowNegative={false}
      decimalSeparator=","
      hideControls
      ref={ref}
      {...props}
    />
  )
})

export default DecimalInput
