/* eslint-disable max-len */
import { TFunction } from 'i18next'
import Decimal from 'decimal.js'
import { PriceTier } from '../../../models'
import InvoicePriceTier from '../../../models/invoice-price-tier'
import formatToCurrency from '../../../shared/utils/helpers/format-to-currency'
import { BillingType } from '../../price-tier/enum'
import { isInvoicePriceTier } from './type-checks'

const getInvoiceSummaryContent = (t: TFunction, language: string, priceTier: InvoicePriceTier | PriceTier) => {
  const usageString = isInvoicePriceTier(priceTier) ? `- ${t('invoice:price-tier-item:quantity')} ${priceTier.usage}` : ''
  const packageString = isInvoicePriceTier(priceTier) ? `- ${t('invoice:price-tier-item:package-quantity')} ${Math.ceil(priceTier.usage / priceTier.packageSize!)}` : ''

  const content = {
    [BillingType.Flat]: `${t('invoice:price-tier-item:value')} ${formatToCurrency({ price: new Decimal(priceTier.price).times(100).toNumber(), language })}`,
    [BillingType.Unit]: `${t('invoice:price-tier-item:unit-value')} ${formatToCurrency({ price: new Decimal(priceTier.price).times(100).toNumber(), language })} ${usageString}`,
    [BillingType.Package]: `${t('invoice:price-tier-item:package-value')} ${formatToCurrency({ price: new Decimal(priceTier.price).times(100).toNumber(), language })} ${packageString}`,
  }
  return content[priceTier.billingType]
}

export { getInvoiceSummaryContent }
