import { create } from 'zustand'
import { User } from '../../../models'
import getUserSessionFromLocalStorage from '../../../shared/utils/user/get-user-session-from-local-storage'
import userSessionUtils from '../utils/user-session-utils'

interface UserSearchTermState {
  loggedUser: User
  isUserAuthenticated: boolean
  actions: {
    setLoggedUser: (user: User) => void
  }
}

const getDefaultLoggedUser = () => {
  const userSession = getUserSessionFromLocalStorage()

  if (!userSession) {
    return {
      id: '',
      name: '',
      email: '',
      groups: [],
    }
  }

  return userSession.user
}

const getDefaultIsUserAuthenticated = () => {
  const userSession = getUserSessionFromLocalStorage()

  return userSession !== null
}

const useAuthUserStore = create<UserSearchTermState>((set) => ({
  loggedUser: getDefaultLoggedUser(),
  isUserAuthenticated: getDefaultIsUserAuthenticated(),
  actions: {
    setLoggedUser: (user: User) => {
      set({ loggedUser: user, isUserAuthenticated: true })
      userSessionUtils.updateUserSession(user)
    },
  },
}))

export const useAuthUserLoggedUser = () => useAuthUserStore((state) => state.loggedUser)
export const useAuthUserIsUserAuthenticated = () => useAuthUserStore((state) => state.isUserAuthenticated)
export const useAuthUserActions = () => useAuthUserStore((state) => state.actions)
