import { useFormContext, useFieldArray } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import { BillingType } from '../enum'
import PriceTierFormHeader from './price-tier-form-header'
import BillingFlatForm from './billing-flat-form'
import BillingPackageForm from './billing-package-form'
import BillingUnitForm from './billing-unit-form'
import { UpsertExtendedPlanAttributes } from '../../../interfaces'
import PRICE_TIER_STEP from '../constants/price-tier-step'

interface Props {
  metricIndex: number
}

export default function PriceTierForm({ metricIndex }: Props) {
  const {
    control, setValue, getValues,
  } = useFormContext<UpsertExtendedPlanAttributes>()

  const { fields, append, remove } = useFieldArray({
    control,
    name: `metrics.${metricIndex}.priceTiers`,
  })

  const fixPriceTierToValueAfterAppend = () => {
    const currentIndex = fields.length
    if (currentIndex === 0) return

    const priceTierToStep = PRICE_TIER_STEP

    let fixPreviousToPrice = currentIndex * priceTierToStep

    if (currentIndex > 1) {
      const previousToPrice = getValues(`metrics.${metricIndex}.priceTiers.${currentIndex - 2}.to`)
      if (previousToPrice) {
        fixPreviousToPrice = previousToPrice + priceTierToStep
      }
    }
    setValue(`metrics.${metricIndex}.priceTiers.${currentIndex - 1}.to`, fixPreviousToPrice)
  }

  const handleAppendNewPriceTier = (billingType: BillingType) => {
    append({
      renderId: uuidv4(),
      billingType,
      from: 1,
      to: null,
      packageSize: billingType === BillingType.Package ? 1 : null,
      price: '0',
    })
    fixPriceTierToValueAfterAppend()
  }

  const handleRemovePriceTier = (index: number) => {
    remove(index)
  }

  return (
    <div>
      <div className="my-4">
        <PriceTierFormHeader handleAppendNewPriceTier={handleAppendNewPriceTier} metricIndex={metricIndex} />
      </div>

      <div>
        {fields.map((field, index) => (
          <div key={field.renderId || field.id} className="mb-3">

            {field.billingType === BillingType.Flat && (
              <BillingFlatForm
                metricIndex={metricIndex}
                remove={handleRemovePriceTier}
                index={index}
              />
            )}
            {field.billingType === BillingType.Package && (
              <BillingPackageForm
                metricIndex={metricIndex}
                remove={handleRemovePriceTier}
                index={index}
              />
            )}
            {field.billingType === BillingType.Unit && (
              <BillingUnitForm
                metricIndex={metricIndex}
                remove={handleRemovePriceTier}
                index={index}
              />
            )}

          </div>
        ))}
      </div>
    </div>
  )
}
