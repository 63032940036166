import {
  Button, Checkbox, NumberInput, Select,
} from '@mantine/core'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { BiLockAlt, BiLockOpenAlt } from 'react-icons/bi'
import ContractPlansForm from './contract-plans-form'
import Divider from '../../../shared/components/display/divider'
import UpsertContractAttributes from '../../../interfaces/contract/upsert-contract-attributes'
import { ContractNonBusinessDayHandling } from '../enum'
import { UsePaymentAccountsQuery } from '../../payment-account/queries'
import buildPaymentAccountOptions from '../../payment-account/utils/build-payment-account-options'
import nonBusinessDaySelectOptions from '../utils/non-business-day-select-options'
import ContractDatesInput from './contract-dates-input'
import Tooltip from '../../../shared/components/form/tooltip-input'

interface Props {
  isUpdate?: boolean
}

export default function ContractForm({ isUpdate }: Props) {
  const { t } = useTranslation()
  const { id: customerId } = useParams()

  const [isCheckboxMonthLastDay, setCheckboxMonthLastDay] = useState<boolean>(false)
  const [isBillingEndDayLocked, setIsBillingEndDayLocked] = useState(true)
  const [isDueDateLocked, setIsDueDateLocked] = useState(true)

  const {
    control, formState: { errors }, watch, setValue, getValues,
  } = useFormContext<UpsertContractAttributes>()

  const { data: paymentAccountOptions } = UsePaymentAccountsQuery(customerId, { select: buildPaymentAccountOptions })

  const paymentAccountId = watch('paymentAccountId')
  const { current: billingEndDay } = useRef(getValues('billingEndDay'))

  const handleCheckboxMonthLastDay = (value: boolean) => {
    if (value) setValue('billingEndDay', 31)
    setCheckboxMonthLastDay(value)
  }

  useEffect(() => {
    if (isUpdate && billingEndDay === 31) setCheckboxMonthLastDay(true)
  }, [isUpdate, billingEndDay])

  return (
    <div className="flex flex-col overflow-auto">
      <div className="w-full flex flex-col gap-4">
        <Controller
          name="paymentAccountId"
          control={control}
          rules={{ required: true }}
          defaultValue={undefined}
          render={({ field }) => (
            <Select
              {...field}
              className="w-full"
              label={t('customer:payment-account:name') as string}
              placeholder={t('customer:payment-account:add-payment-account') as string}
              value={paymentAccountId || null}
              data={paymentAccountOptions || []}
              error={errors.paymentAccountId && errors.paymentAccountId?.message}
              required
              searchable
              nothingFoundMessage={t('common:input.not-found') as string}
            />
          )}
        />
        <ContractDatesInput isUpdate={isUpdate} />

        <div className={`flex w-full rounded-lg px-4 pt-2 pb-4 ${isUpdate && isBillingEndDayLocked ? 'bg-gray-50' : 'border-[1px] border-gray-200'}  gap-4`}>
          <div className="w-full">
            {isUpdate ? (
              <Controller
                name="billingEndDay"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <NumberInput
                    label={t('customer:invoice-billing-day')}
                    placeholder={t('customer:billing-day') as string}
                    description={!isBillingEndDayLocked && t('customer:form:billing-day-change-alert') as string}
                    inputWrapperOrder={['label', 'input', 'description', 'error']}
                    max={31}
                    rightSection={<Tooltip marginRight text={t('customer:form:billing-day-alert')} />}
                    disabled={isCheckboxMonthLastDay || isBillingEndDayLocked}
                    required
                    {...field}
                  />
                )}
              />
            ) : (
              <Controller
                name="billingEndDay"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <NumberInput
                    required
                    label={t('customer:invoice-billing-day')}
                    placeholder={t('customer:billing-day') as string}
                    min={1}
                    max={31}
                    rightSection={<Tooltip marginRight text={t('customer:form:billing-day-alert')} />}
                    disabled={isCheckboxMonthLastDay}
                    {...field}
                  />
                )}
              />
            )}
          </div>
          <div className="flex w-full mt-8 gap-2">
            <Checkbox
              defaultChecked
              checked={isCheckboxMonthLastDay}
              onChange={(event) => handleCheckboxMonthLastDay(event.currentTarget.checked)}
              disabled={isUpdate && isBillingEndDayLocked}
              color="violet"
              label={t('customer:month-last-day')}
            />
          </div>
          {isUpdate
            && (
            <Button
              variant="outline"
              color="violet"
              className="w-5 min-w-0 mt-6"
              onClick={() => setIsBillingEndDayLocked(!isBillingEndDayLocked)}
            >
              {isBillingEndDayLocked ? <BiLockAlt size={20} /> : <BiLockOpenAlt size={20} />}
            </Button>
            )}
        </div>

        <div className={`flex w-full rounded-lg px-4 pt-2 pb-4 ${isUpdate && isDueDateLocked ? 'bg-gray-50' : 'border-[1px] border-gray-200'} gap-4`}>
          <div className="w-full">
            {isUpdate ? (
              <Controller
                name="dueDateDay"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <NumberInput
                    label={t('customer:invoice-expiring-day')}
                    required
                    placeholder={t('customer:expiring-day') as string}
                    max={31}
                    rightSection={<Tooltip marginRight text={t('customer:form:expiring-day-alert')} />}
                    disabled={isDueDateLocked}
                    {...field}
                  />
                )}
              />
            ) : (
              <Controller
                name="dueDateDay"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <NumberInput
                    required
                    label={t('customer:invoice-expiring-day')}
                    placeholder={t('customer:expiring-day') as string}
                    min={1}
                    max={31}
                    rightSection={<Tooltip marginRight text={t('customer:form:expiring-day-alert')} />}
                    {...field}
                  />
                )}
              />
            )}
          </div>
          <div className="flex w-full gap-2">
            <Controller
              name="contractNonBusinessDayHandling"
              control={control}
              rules={{ required: true }}
              defaultValue={nonBusinessDaySelectOptions[0].value as ContractNonBusinessDayHandling}
              render={({ field }) => (
                <Select
                  {...field}
                  className="w-full"
                  label={t('customer:non-business-days') as string}
                  data={nonBusinessDaySelectOptions}
                  error={errors.contractNonBusinessDayHandling && errors.contractNonBusinessDayHandling?.message}
                  required
                  searchable
                  disabled={isUpdate && isDueDateLocked}
                  nothingFoundMessage={t('common:input.not-found') as string}
                />
              )}
            />
            {isUpdate
            && (
            <Button
              variant="outline"
              color="violet"
              className="w-5 min-w-0 mt-6"
              onClick={() => setIsDueDateLocked(!isDueDateLocked)}
            >
              {isDueDateLocked ? <BiLockAlt size={20} /> : <BiLockOpenAlt size={20} />}
            </Button>
            )}
          </div>
        </div>
      </div>

      <div className="my-4">
        <Divider />
      </div>

      <div className="flex flex-col w-full h-full">
        <ContractPlansForm />
      </div>
    </div>
  )
}
