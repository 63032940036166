import Typography from '../general/typography'

interface Props {
  value: number | string;
}

export function TableDefaultCell({ value }: Props) {
  return (
    <Typography>
      {value}
    </Typography>
  )
}
