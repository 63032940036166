import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import searchHelper from '../../shared/utils/helpers/search-helper'
import { useInvoiceSearchTerm } from './store/invoice-search-term-store'
import tableInvoiceUtils from './utils/table-invoice-utils'
import invoicesService from './services/invoice-service'
import { useInvoiceFilter } from './store/invoice-filter-store'
import { getFilteredInvoices } from './helpers/invoice-filter-helper'
import { ExtendedInvoice, Invoice } from '../../models'
import { QueryParams } from '../../interfaces'
import MutationParams from '../../interfaces/queries/mutation-params'
import queryClient from '../../config/query-client'
import i18n from '../../config/i18n'
import UpsertInvoiceAdditionalItemAttributes from '../../interfaces/invoice/upsert-invoice-additional-item-attributes'

const invoiceKeys = {
  all: [{ scope: 'invoices' }] as const,
  lists: () => [{ ...invoiceKeys.all[0], entity: 'list' }] as const,
  single: (id: string) => [{ ...invoiceKeys.all[0], entity: 'single', id }] as const,
}

export const useInvoicesQuery = <ModelSchema = Invoice[]>(params?: QueryParams<Invoice[], ModelSchema>) => useQuery({
  queryKey: invoiceKeys.lists(),
  queryFn: invoicesService.fetchInvoices,
  select: params?.select,
})

export const useFilteredInvoicesQuery = () => {
  const searchTerm = useInvoiceSearchTerm()
  const { partialMatchKeys, fullMatchKeys } = tableInvoiceUtils

  const filter = useInvoiceFilter()
  return useInvoicesQuery({
    select: (queriedInvoices) => getFilteredInvoices(searchHelper.getFilteredDataBySearchTerm(queriedInvoices, partialMatchKeys, fullMatchKeys, searchTerm), filter),
  })
}

export const useInvoiceQuery = (id?: string) => useQuery({
  queryKey: invoiceKeys.single(id!),
  queryFn: () => invoicesService.fetchInvoice({ id: id! }),
  enabled: !!id,
})

export const useDowloadInvoiceDocument = () => useMutation({
  mutationFn: invoicesService.downloadInvoiceDocument,
})

export const useUpdateInvoiceAdditionalItems = (params?: MutationParams<ExtendedInvoice>) => useMutation(
  (data: { invoiceId: string; additionalItems: UpsertInvoiceAdditionalItemAttributes[], currentInvoiceAdditionalItems: UpsertInvoiceAdditionalItemAttributes[] }) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    invoicesService.updateInvoiceAdditionalItems(data.invoiceId, data.additionalItems, data.currentInvoiceAdditionalItems),
  {
    onSuccess: (updatedInvoice: ExtendedInvoice) => {
      queryClient.setQueryData<ExtendedInvoice>(
        invoiceKeys.single(updatedInvoice.id),
        updatedInvoice,
      )
      toast.success(i18n.t('messages:success.default'))
      params?.onSuccess?.(updatedInvoice)
    },
  },
)

export default {
  invoiceKeys,
  useFilteredInvoicesQuery,
  useDowloadInvoiceDocument,
  useUpdateInvoiceAdditionalItems,
}
