import TableInvoices from '../features/invoice/components/invoice-table/table-invoices'
import InvoicePageActions from '../features/invoice/components/general-component/invoice-page-actions'

export default function Invoices() {
  return (
    <div className="h-full w-full flex flex-col">
      <InvoicePageActions />
      <TableInvoices />
    </div>
  )
}
