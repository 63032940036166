import { useTranslation } from 'react-i18next' // If you're using i18next for translations
import InvoicePlan from '../../../../../models/invoice-plan'
import InvoicePlanDetailCard from './invoice-plan-detail-card'
import EmptyContentMessage from '../../../../../shared/components/designs/empty-content-message' // Update the import path as necessary

interface Props {
  plans: InvoicePlan[];
}

export default function InvoicePlansDetail({ plans }: Props) {
  const { t } = useTranslation()

  const invoiceWithoutPlans = plans.length === 0

  return (
    <div>
      {invoiceWithoutPlans ? (
        <div className="mt-12">
          <EmptyContentMessage text={t('invoice:empty-plans')} />
        </div>
      ) : (
        plans.map((plan) => (
          <div key={plan.id} className="mb-4 w-[99%]">
            <InvoicePlanDetailCard plan={plan} />
          </div>
        ))
      )}
    </div>
  )
}
