import { Badge } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { BillingCycleStatus } from '../../enum'

interface Props {
  variant: BillingCycleStatus;
  size?: 'sm' | 'md' | 'lg';
}

export function InvoiceStatusBadge({ variant, size = 'md' }: Props) {
  const { t } = useTranslation(['invoice'])

  switch (variant) {
    case BillingCycleStatus.Paid:
      return <Badge size={size} variant="outline" bg="green.0" color="green">{t('invoice:status-label:paid')}</Badge>

    case BillingCycleStatus.Canceled:
      return <Badge size={size} variant="outline" bg="gray.0" color="gray">{t('invoice:status-label:canceled')}</Badge>

    case BillingCycleStatus.Open:
      return <Badge size={size} variant="outline" bg="blue.0" color="blue">{t('invoice:status-label:open')}</Badge>

    case BillingCycleStatus.Closed:
      return <Badge size={size} variant="outline" bg="yellow.0" color="yellow">{t('invoice:status-label:closed')}</Badge>

    default:
      return <Badge size={size} variant="outline" bg="blue.0" color="blue">{t('invoice:status-label:open')}</Badge>
  }
}
