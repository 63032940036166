import i18n from '../../../config/i18n'

const nonBusinessDaySelectOptions = [
  {
    label: i18n.t('customer:contract-non-business-day:antecipate'),
    value: 'antecipate',
  },
  {
    label: i18n.t('customer:contract-non-business-day:postpone'),
    value: 'postpone',
  },
]

export default nonBusinessDaySelectOptions
