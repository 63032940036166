import { toast } from 'react-toastify'
import { UpsertPaymentAccountAttributes } from '../../../interfaces'
import { formatTaxId } from './format-tax-id'
import i18n from '../../../config/i18n'

const upsertPaymentAccountAttributesToCreatePaymentAccountRequest = (upsertPaymentAccountAttributes: UpsertPaymentAccountAttributes) => {
  const createPaymentAccountRequest = {
    businessName: upsertPaymentAccountAttributes.businessName,
    taxId: formatTaxId(upsertPaymentAccountAttributes.taxId),
    customerId: upsertPaymentAccountAttributes.customerId,
    email: upsertPaymentAccountAttributes.email || null,
    tradeName: upsertPaymentAccountAttributes.tradeName || null,
  }

  return createPaymentAccountRequest
}

const upsertPaymentAccountAttributesToUpdatePaymentAccountRequest = (upsertPaymentAccountAttributes: UpsertPaymentAccountAttributes) => {
  const { id } = upsertPaymentAccountAttributes

  if (!id) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw toast.error(i18n.t('messages:error.default'))
  }

  const updatePaymentAccountRequest = {
    id,
    businessName: upsertPaymentAccountAttributes.businessName,
    taxId: formatTaxId(upsertPaymentAccountAttributes.taxId),
    customerId: upsertPaymentAccountAttributes.customerId,
    email: upsertPaymentAccountAttributes.email || null,
    tradeName: upsertPaymentAccountAttributes.tradeName || null,
  }

  return updatePaymentAccountRequest
}

const upsertPaymentAccountUtils = {
  upsertPaymentAccountAttributesToCreatePaymentAccountRequest,
  upsertPaymentAccountAttributesToUpdatePaymentAccountRequest,
}

export default upsertPaymentAccountUtils
