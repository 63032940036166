import { modals } from '@mantine/modals'
import { Trans, useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'
import AlertCard from '../../../../shared/components/alert/alert-card'
import { UseCancelContract } from '../../queries'
import { ExtendedContract } from '../../../../models'

export const useCancelContractAlertModal = (customerId?: string) => {
  const { t } = useTranslation()

  const { mutate } = UseCancelContract(customerId)

  return (contract: ExtendedContract) => {
    modals.open({
      withCloseButton: false,
      children: (
        <AlertCard
          variant="warning"
          title={t('customer:contract:cancel-alert-modal:title')}
          description={(
            <div className="flex flex-col gap-5">
              <Text>{t('customer:contract:cancel-alert-modal:content')}</Text>
              <Text>
                <Trans i18nKey={t('customer:contract:cancel-alert-modal:content-warning')} />
              </Text>
            </div>
          )}
          onContinue={() => {
            mutate(contract.id)
            modals.closeAll()
          }}
          onCancel={modals.closeAll}
        />
      ),
    })
  }
}
