import { useTranslation } from 'react-i18next'
import { Button } from '@mantine/core'
import InvoiceDetailCustomerCard from './invoice-detail-customer-card'
import InvoiceDetailCard from './invoice-detail-card'
import { ExtendedInvoice } from '../../../../models'

interface Props {
  invoice: ExtendedInvoice;
  handleOpenManageInvoiceModal: () => void;
}

export default function InvoiceDetailSideContent({ invoice, handleOpenManageInvoiceModal }: Props) {
  const { t } = useTranslation(['invoice'])

  return (
    <div className=" w-3/12">
      <div className="w-full flex flex-col pr-2 gap-4">

        <InvoiceDetailCustomerCard customer={invoice.customer} />

        <InvoiceDetailCard
          totalAmount={invoice.totalAmount}
          status={invoice.status}
          endDate={invoice.endDate}
        />

        <Button
          variant="outline"
          fullWidth
          onClick={handleOpenManageInvoiceModal}
        >
          {t('invoice:manage')}
        </Button>

      </div>

    </div>

  )
}
