import { t } from 'i18next'

export const formatPlanNamesForContract = (planNames: string[]) => {
  if (planNames.length <= 2) {
    return planNames.join(', ')
  }
  const firstThreePlansNames = planNames.slice(0, 2).join(', ')
  const remainingCount = planNames.length - 2
  return `${t('resource:delete-error-alert:plans', { plans: firstThreePlansNames, count: remainingCount })}`
}
