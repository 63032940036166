import { useEffect, useState } from 'react'
import { ComboboxItem, Select } from '@mantine/core'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePlansQuery } from '../../plan/queries'
import ContentCard from '../../../shared/components/general/content-box'
import { UpsertPlanAttributes } from '../../../interfaces'
import ViewPlanModal from '../../plan/components/view-plan-modal'
import UpsertContractAttributes from '../../../interfaces/contract/upsert-contract-attributes'

export default function ContractPlansForm() {
  const { t } = useTranslation(['customer', 'common', 'plan'])

  const [plansSelectData, setPlansSelectData] = useState<ComboboxItem[]>([])
  const [viewPlanId, setViewPlanId] = useState<string | undefined>(undefined)

  const {
    data: plans,
    isLoading,
  } = usePlansQuery()

  const {
    control,
  } = useFormContext<UpsertContractAttributes>()

  const { fields: selectedPlans, prepend, remove } = useFieldArray({
    control,
    name: 'plans',
    keyName: 'renderId',
  })

  const handlePlanSelect = (planId: string | null) => {
    if (!planId || !plans) return

    const selectedPlan = plans.find((plan) => plan.id === planId)

    if (!selectedPlan) return

    prepend(selectedPlan)
    setPlansSelectData((currentPlansSelectData) => currentPlansSelectData.filter((planSelectData) => planSelectData.value !== planId))
  }

  const handleSelectedPlanDelete = (deletedPlan: UpsertPlanAttributes, index: number) => {
    if (!deletedPlan.id) return

    remove(index)
    setPlansSelectData((currentPlansSelectData) => [
      ...currentPlansSelectData,
      {
        value: deletedPlan.id as string,
        label: deletedPlan.name,
      },
    ])
  }

  useEffect(() => {
    if (!plans) {
      setPlansSelectData([])
      return
    }

    const plansToBeSelected = plans?.filter((plan) => {
      const foundSelectedPlan = selectedPlans.find((selectedPlan) => plan.id === selectedPlan.id)

      return !foundSelectedPlan
    })

    setPlansSelectData(plansToBeSelected.map((plan) => ({
      value: plan.id,
      label: plan.name,
    })))
  }, [plans, selectedPlans])

  return (
    <div>
      <Select
        label={(
          <>
            {t('plan:plan')}
            {' '}
            <span className="text-red-600">*</span>
          </>
        )}
        placeholder={t('customer:add-plan') as string}
        searchable
        nothingFoundMessage={t('common:input.not-found')}
        maxDropdownHeight={280}
        data={plansSelectData}
        disabled={isLoading}
        className="mb-5"
        value={null}
        onChange={(e) => handlePlanSelect(e)}
      />
      <div className="w-content">
        {selectedPlans.map((selectedPlan, index) => (
          <div className="mb-5 w-full" key={selectedPlan.id}>
            <ContentCard
              title={selectedPlan.name}
              content={selectedPlan.description || ''}
              onClick={() => setViewPlanId(selectedPlan.id)}
              onDelete={() => handleSelectedPlanDelete(selectedPlan, index)}
            />
          </div>
        ))}
      </div>

      {viewPlanId && (
        <ViewPlanModal
          planId={viewPlanId}
          onClose={() => setViewPlanId(undefined)}
          opened
        />
      )}
    </div>
  )
}
