import { Button, Card, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { BiDownload } from 'react-icons/bi'
import { useDowloadInvoiceDocument } from '../../queries'
import i18n from '../../../../config/i18n'

interface Props {
  invoiceId: string;
  customerName: string;
  endDate: Date;
}

export default function InvoiceDetailHeader({ invoiceId, customerName, endDate }: Props) {
  const { t } = useTranslation(['invoice'])

  const endDateFormatted = new Date(endDate)

  const { mutate, isLoading } = useDowloadInvoiceDocument()

  return (
    <Card shadow="sm" padding="lg" radius="md" className="h-30 bg-violet-900 flex-row justify-between items-center">
      <div className="flex flex-col gap-3">
        <Text size="lg" fw={500} className="text-white">
          {`${t('invoice:invoice')} #${invoiceId}`}
        </Text>
        <Text size="md" fw={400} className="text-white">
          {customerName}
        </Text>
        <Text size="md" fw={300} className="text-white">
          {`${t('invoice:end-date')}: ${endDateFormatted.toLocaleDateString(i18n.language)}`}
        </Text>
      </div>
      <div>
        <Button
          size="md"
          leftSection={<BiDownload />}
          onClick={() => mutate(invoiceId)}
          loading={isLoading}
        >
          {t('invoice:download')}
        </Button>
      </div>
    </Card>
  )
}
