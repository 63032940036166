import { useEffect, useState } from 'react'
import { useFormContext, useFieldArray, Controller } from 'react-hook-form'
import {
  Accordion, Button, Select, TextInput, Text,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { BiTrashAlt } from 'react-icons/bi'
import { v4 as uuidv4 } from 'uuid'
import { MAX_TEXT_FIELD_LENGTH } from '../../../shared/utils/constants'
import { Aggregation, BillingModel, PriceTierDivision } from '../enum'
import MetricFormHeader from './metric-form-header'
import PriceTierForm from '../../price-tier/components/price-tier-form'
import { UpsertExtendedPlanAttributes } from '../../../interfaces'
import buildResourceOptions from '../../resource/utils/build-resource-options'
import { useResourcesQuery } from '../../resource/queries'
import CentsInput from '../../../shared/components/form/cents-input'
import BillingModelAndAggregationInput from './billing-model-and-aggregation-input'

export default function MetricForm() {
  const { t } = useTranslation(['common', 'metric'])

  const {
    register, control, watch, setValue, getValues, formState: { errors },
  } = useFormContext<UpsertExtendedPlanAttributes>()

  const [metricOpen, setMetricOpen] = useState<string | null>(null)

  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'metrics',
  })

  const { data: resourceOptions } = useResourcesQuery({ select: buildResourceOptions })

  const handleAppendNewMetric = () => {
    prepend({
      renderId: uuidv4(),
      resourceId: '',
      name: t('metric:new-metric'),
      billingModel: BillingModel.InFull,
      aggregation: Aggregation.Sum,
      priceTierDivision: PriceTierDivision.Progressive,
      // minAmount: null,
      // maxAmount: null,
      fixedAmount: 0,
      priceTiers: [],
    })
  }

  useEffect(() => {
    setMetricOpen('metric.0')
  }, [fields])

  const metricNames = watch('metrics')

  return (
    <div>
      <div className="my-7">
        <MetricFormHeader handleAppendNewMetric={handleAppendNewMetric} />
      </div>
      <Accordion variant="separated" radius="md" value={metricOpen} onChange={setMetricOpen}>
        {fields.map((_field, index) => (
          <div key={_field.renderId || _field.id} className="mb-5">
            <Accordion.Item value={`metric.${index}`}>
              <Accordion.Control>
                <Text className="truncate w-90 text-primary text-h5">
                  {metricNames?.[index]?.name || t('metric:new-metric')}
                </Text>
              </Accordion.Control>
              <Accordion.Panel>

                <div className="mx-1">
                  <div className="flex flex-row justify-between gap-4">
                    <Controller
                      name={`metrics.${index}.resourceId`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className="mb-4 w-full"
                          label={`${t('metric:resource') as string}`}
                          placeholder={t('metric:resource') as string}
                          data={resourceOptions || []}
                          value={metricNames?.[index]?.resourceId || null}
                          onSelect={(e) => {
                            const metricName = getValues(`metrics.${index}.name`)
                            const resourceName = e.currentTarget.value
                            if (resourceName && metricName === t('metric:new-metric')) {
                              setValue(`metrics.${index}.name`, resourceName)
                            }
                          }}
                          error={errors.metrics?.[index]?.resourceId && errors.metrics?.[index]?.message}
                          required
                          searchable
                        />
                      )}
                    />
                  </div>

                  <TextInput
                    className="mb-4"
                    label={`${t('metric:name') as string}`}
                    placeholder={t('metric:name') as string}
                    error={errors.metrics?.[index]?.name && errors.metrics?.[index]?.message}
                    {...register(`metrics.${index}.name`, {
                      required: { value: true, message: t('common:form-error:required-field') },
                      maxLength: { value: MAX_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
                    })}
                    required
                  />

                  <BillingModelAndAggregationInput index={index} />

                  <CentsInput
                    className="mb-4 w-full"
                    label={`${t('metric:fixed-amount') as string}`}
                    placeholder={t('common:form-placeholder:decimal-zero') as string}
                    error={errors.metrics?.[index]?.fixedAmount && errors.metrics?.[index]?.message}
                    value={getValues(`metrics.${index}.fixedAmount`)}
                    onChange={(value: number) => setValue(`metrics.${index}.fixedAmount`, value)}
                  />

                  <PriceTierForm metricIndex={index} />

                  <Button
                    style={{
                      justifyContent: 'flex-start',
                    }}
                    onClick={() => remove(index)}
                    leftSection={<BiTrashAlt size={22} />}
                    variant="subtle"
                    color="red.9"
                    size="xs"
                  >
                    {t('metric:delete-metric')}
                  </Button>
                </div>

              </Accordion.Panel>
            </Accordion.Item>
          </div>
        ))}
      </Accordion>
    </div>
  )
}
