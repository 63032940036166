import { ReactElement } from 'react'
import SimplePageLayout from './simple-page-layout'
import PageLayout from './page-layout'
import useCurrentPage from '../../../shared/hooks/use-current-page'
import MobilePage from './exception-pages/mobile-page'
import useIsMobile from '../../../shared/hooks/use-is-mobile'

interface Props {
  children: ReactElement;
}

export default function PageWrapper({ children }: Props) {
  const { currentPage } = useCurrentPage()
  const isMobile = useIsMobile()

  const isLayoutSimple = currentPage && currentPage.hasSimpleLayout

  if (isMobile) return <MobilePage />

  return (
    isLayoutSimple ? (
      <SimplePageLayout>
        {children}
      </SimplePageLayout>
    ) : (
      <PageLayout>
        {children}
      </PageLayout>
    )
  )
}
