import IMask from 'imask'
import { cnpj } from 'cpf-cnpj-validator'
import { forwardRef } from 'react'
import { TextInput, TextInputProps } from '@mantine/core'
import { Controller, useFormContext } from 'react-hook-form'

interface Props extends TextInputProps {
  name: string;
}

const TaxIdInput = forwardRef<HTMLInputElement, Props>(({ name, ...props }) => {
  const { setError, clearErrors } = useFormContext()

  const maskConfig = {
    mask: [
      // {
      //   mask: '000.000.000-00',
      //   maxLength: 11,
      // },
      {
        mask: '00.000.000/0000-00',
        // maxLength: 14,
      },
    ],
  }

  const handleValidation = (value: string) => {
    clearErrors(name)

    if (value.length < 11) return
    // if (value.length === 14) {
    //  if (!cpf.isValid(value)) {
    //    setError(name, { type: 'manual', message: 'CPF inválido' })
    //  }
    // }
    if (value.length === 18) {
      if (!cnpj.isValid(value)) {
        setError(name, { type: 'manual', message: 'CNPJ inválido' })
      }
    }
  }

  return (
    <Controller
      name={name}
      render={({ field: { ref, ...field } }) => (
        <div ref={ref}>
          <TextInput
            {...props}
            {...field}
            onBlur={(event) => {
              handleValidation(event.currentTarget.value)
            }}
            ref={(el) => {
              if (!el) return
              IMask(el, maskConfig)
            }}
          />
        </div>
      )}
    />
  )
})

export default TaxIdInput
