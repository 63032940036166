import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TableColumn } from '../../../interfaces'
import tablePlansUtils from '../utils/table-plan-utils'
import { CoreTable } from '../../../shared/components/table'
import { useFilteredPlansQuery } from '../queries'
import ViewPlanModal from './view-plan-modal'
import { Plan } from '../../../models'
import { TablePlanMenuCell } from './table-plan-menu-cell'

export default function TablePlan() {
  const { t } = useTranslation(['plan', 'common'])

  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null)
  const [isViewPlanModalOpen, setIsViewPlanModalOpen] = useState(false)

  const {
    data: plans,
    isLoading,
    isFetching,
  } = useFilteredPlansQuery()

  const onViewPlanHandler = async (plan: Plan) => {
    setSelectedPlan(plan)
    setIsViewPlanModalOpen(true)
  }

  const handleViewPlanModalClose = () => {
    setIsViewPlanModalOpen(false)
    setSelectedPlan(null)
  }

  const tablePlanColumns: TableColumn<Plan>[] = [
    {
      label: t('plan:name'),
      key: 'name',
      columnKey: 'name',
      widthPercentage: 30,
    },
    {
      label: t('plan:description'),
      key: 'description',
      columnKey: 'description',
      widthPercentage: 60,
    },
    {
      label: '',
      key: 'id',
      columnKey: 'planId',
      Component: TablePlanMenuCell,
      ignoreRowClick: true,
      widthPercentage: 10,
    },
  ]

  return (
    <>
      {selectedPlan && (
        <ViewPlanModal
          opened={isViewPlanModalOpen}
          onClose={handleViewPlanModalClose}
          planId={selectedPlan.id}
        />
      )}
      <CoreTable<Plan>
        data={plans || []}
        indexKey={tablePlansUtils.indexKey}
        columns={tablePlanColumns}
        isFetching={isFetching}
        isLoading={isLoading}
        pageConfiguration={{
          pageVariation: 1,
        }}
        rowAction={onViewPlanHandler}
      />
    </>
  )
}
