import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import i18n from 'i18next'
import queryClient from '../../config/query-client'
import searchHelper from '../../shared/utils/helpers/search-helper'
import { useUserSearchTerm } from './store/user-search-term-store'
import tableUserUtils from './utils/table-user-utils'
import usersService from './services/user-service'
import { useAuthUserActions, useAuthUserLoggedUser } from '../auth/store/auth-user-store'
import { QueryParams } from '../../interfaces'
import ExtendedUser from '../../models/extended-user'
import MutationParams from '../../interfaces/queries/mutation-params'

const userKeys = {
  all: [{ scope: 'users' }] as const,
  lists: () => [{ ...userKeys.all[0], entity: 'list' }] as const,
}

export const useUsersQuery = <ModelSchema = ExtendedUser[]>(params?: QueryParams<ExtendedUser[], ModelSchema>) => useQuery({
  queryKey: userKeys.lists(),
  queryFn: usersService.fetchUsers,
  select: params?.select,
})

export const useFilteredUsersQuery = () => {
  const searchTerm = useUserSearchTerm()
  const { partialMatchKeys, fullMatchKeys } = tableUserUtils

  return useUsersQuery({ select: (queriedInvoices) => searchHelper.getFilteredDataBySearchTerm(queriedInvoices, partialMatchKeys, fullMatchKeys, searchTerm) })
}

export const useCreateUser = ({ onSuccess }: { onSuccess: () => void }) => useMutation({
  mutationFn: usersService.createUser,
  onSuccess: (newUser) => {
    queryClient.setQueryData<ExtendedUser[]>(userKeys.lists(), (users) => {
      if (!users) return [newUser]

      return [newUser, ...users]
    })

    toast.success(i18n.t('messages:success.default'))
    onSuccess()
  },
})

export const usePartialUpdateSelfUser = ({ onSuccess }: { onSuccess: () => void }) => {
  const authUserActions = useAuthUserActions()
  const loggedUser = useAuthUserLoggedUser()

  return useMutation({
    mutationFn: usersService.partialUpdateSelfUser,
    onSuccess: (updatedUser) => {
      if (updatedUser.id === loggedUser.id) authUserActions.setLoggedUser(updatedUser)

      queryClient.setQueryData<ExtendedUser[]>(userKeys.lists(), (users) => {
        if (!users) return []

        const updatedAdminUser = { ...updatedUser, customer: null }

        return users.map((user) => (user.id === updatedUser.id ? updatedAdminUser : user))
      })

      toast.success(i18n.t('messages:success.default'))
      onSuccess()
    },
  })
}

export const useUpdateUser = (params?: MutationParams<ExtendedUser>) => useMutation({
  mutationFn: usersService.updateUser,
  onSuccess: (updatedUser) => {
    queryClient.setQueryData<ExtendedUser[]>(userKeys.lists(), (users) => {
      if (!users) return []

      return users.map((user) => (user.id === updatedUser.id ? updatedUser : user))
    })
    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess(updatedUser)
  },
})

export const useDeleteUser = (params?: MutationParams<void>) => useMutation({
  mutationFn: (id: string) => usersService.deleteUser({ id }),
  onSuccess: (_data, id) => {
    queryClient.setQueryData<ExtendedUser[]>(userKeys.lists(), (users) => {
      if (!users) return []

      return users.filter((user) => (user.id !== id))
    })
    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess()
  },
  onError: params?.onError,
})

export default userKeys
