import { DateInput, DateInputProps } from '@mantine/dates'
import { BiCalendar } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Checkbox } from '@mantine/core'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import IMask from 'imask/holder'
import UpsertContractAttributes from '../../../interfaces/contract/upsert-contract-attributes'

dayjs.extend(customParseFormat)

interface Props {
  isUpdate?: boolean
}

export default function ContractDatesInput({ isUpdate }: Props) {
  const { t, i18n } = useTranslation()

  const {
    setValue, watch, control,
  } = useFormContext<UpsertContractAttributes>()

  const isAutoRenewalEnabled = watch('isAutoRenewalEnabled')

  const handleisAutoRenewalEnabled = (isAutoRenewalEnabledValue: boolean) => {
    setValue('isAutoRenewalEnabled', isAutoRenewalEnabledValue)
    if (isAutoRenewalEnabledValue) {
      setValue('endDate', null)
    }
  }

  const dateFormat = i18n.language === 'pt' ? 'DD/MM/YYYY' : 'MM/DD/YYY'
  const dateParser: DateInputProps['dateParser'] = (input) => dayjs(input, dateFormat).toDate()

  const maskConfig = {
    mask: '00/00/0000',
  }

  return (
    <div className="flex gap-4">
      <Controller
        name="startDate"
        rules={{ required: true }}
        control={control}
        render={({ field }) => (
          <DateInput
            {...field}
            required
            placeholder={t('customer:contract:start-date') as string}
            label={t('customer:contract:start-date') as string}
            rightSection={(<BiCalendar className="cursor-pointer" size={18} />)}
            popoverProps={{ withinPortal: false }}
            locale={i18n.language === 'pt' ? 'pt-br' : 'en'}
            valueFormat={dateFormat}
            disabled={isUpdate}
            dateParser={dateParser}
            ref={(el) => {
              if (!el) return
              IMask(el, maskConfig)
            }}
          />
        )}
      />
      {!isAutoRenewalEnabled && (
        <Controller
          name="endDate"
          control={control}
          render={({ field }) => (
            <DateInput
              {...field}
              required
              placeholder={t('customer:contract:end-date') as string}
              label={t('customer:contract:end-date') as string}
              rightSection={(<BiCalendar className="cursor-pointer" size={18} />)}
              popoverProps={{ withinPortal: false }}
              locale={i18n.language === 'pt' ? 'pt-br' : 'en'}
              valueFormat={dateFormat}
              clearable
              dateParser={dateParser}
              ref={(el) => {
                if (!el) return
                IMask(el, maskConfig)
              }}
            />
          )}
        />
      )}
      <div className="flex w-60 mt-8">
        <Checkbox
          defaultChecked
          color="violet"
          label={t('customer:contract:automatic-renewal') as string}
          checked={isAutoRenewalEnabled}
          onChange={(event) => handleisAutoRenewalEnabled(event.currentTarget.checked)}
        />
      </div>
    </div>
  )
}
