import { UpsertExtendedPlanAttributes } from '../../../interfaces'
import RateAdjustPlanAttributes from '../../../interfaces/plan/rate-adjust-plan-attributes'
import { api } from '../../../libs'
import DeletePlanRequest from '../../../libs/api/requests/plans/delete-plan-request'
import GetPlanRequest from '../../../libs/api/requests/plans/get-plan-request'
import { ExtendedPlan, Plan } from '../../../models'

const fetchPlans = async (): Promise<Plan[]> => {
  const getPlansResponse = await api.plans.getPlans()
  return getPlansResponse.data
}

const fetchPlan = async (getPlanAttributes: GetPlanRequest): Promise<ExtendedPlan> => {
  const getPlanResponse = await api.plans.getPlan(getPlanAttributes)
  return getPlanResponse.data
}

const createPlan = async (createPlanAttributes: UpsertExtendedPlanAttributes): Promise<ExtendedPlan> => {
  const createPlanResponse = await api.plans.createPlan(createPlanAttributes)
  return createPlanResponse?.data
}

const updatePlan = async (updatePlanAttributes: UpsertExtendedPlanAttributes): Promise<ExtendedPlan> => {
  const updatePlanResponse = await api.plans.updatePlan(updatePlanAttributes)
  return updatePlanResponse?.data
}

const deletePlan = async (deletePlanAttributes: DeletePlanRequest) => {
  await api.plans.deletePlan(deletePlanAttributes)
}

const rateAdjustPlan = async (rateAdjustPlanAttributes: RateAdjustPlanAttributes): Promise<ExtendedPlan> => {
  const rateAdjustPlanResponse = await api.plans.rateAdjustPlan(rateAdjustPlanAttributes)
  return rateAdjustPlanResponse?.data
}

const planService = {
  fetchPlan,
  fetchPlans,
  createPlan,
  updatePlan,
  deletePlan,
  rateAdjustPlan,
}

export default planService
