import { useState } from 'react'
import {
  Button, Card, Input, Text,
} from '@mantine/core'
import { AiOutlineWarning, AiOutlineInfoCircle, AiOutlineCheckCircle } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

interface Consequence {
  variant: 'secure' | 'danger';
  text: string;
}

interface DeleteAttributes {
  deleteWord: string;
}

interface Props {
  title: string;
  subtitle: string;
  deletedItemName: string;
  conditions?: string[];
  consequences: Consequence[];
  onDelete: () => void;
  onCancel: () => void;
}

export default function DeleteCard({
  title, subtitle, deletedItemName: targetItem, conditions, consequences, onDelete, onCancel,
}: Props) {
  const { t } = useTranslation(['common'])
  const [loading, setLoading] = useState<boolean>(false)

  const {
    register, formState: { isValid },
  } = useForm<DeleteAttributes>({ mode: 'onTouched' })

  const handleDelete = () => {
    setLoading(true)
    onDelete()
  }

  return (
    <Card padding="sm" radius="md">
      <div className="flex flex-row items-center gap-2 mb-3">
        <Button
          radius="md"
          variant="light"
          color="red"
          className="px-2 cursor-default"
        >
          <AiOutlineWarning size={20} />
        </Button>
        <Text size="md" fw={600}>
          {title}
        </Text>
      </div>

      <Text size="sm" fw={500} className="text-justify">
        {subtitle}
        <strong>{targetItem}</strong>
      </Text>

      <hr className="my-4 border-t border-gray-200" />

      {conditions && (
      <div className="mb-4">
        <Text size="sm" fw={400} className="mb-2">{t('common:delete-alert:conditions')}</Text>
        <div className="flex flex-col gap-1">
          {conditions?.map((condition) => (
            <div className="flex gap-2" key={condition}>
              <div className="w-3 m-1">
                <AiOutlineInfoCircle size={15} color="orange" className="mt-1" />
              </div>
              <Text size="sm" fw={400} className="text-justify">
                {condition}
              </Text>
            </div>
          ))}
        </div>
      </div>
      )}

      <Text size="sm" fw={400} className="mb-2">{t('common:delete-alert:consequences')}</Text>

      <div className="flex flex-col gap-2">
        {consequences?.map(({ variant, text }) => (
          <div className="flex gap-1" key={text}>
            <div className="w-3 m-1">
              {variant === 'secure' ? <AiOutlineCheckCircle size={15} color="green" />
                : <AiOutlineInfoCircle size={15} color="red" />}
            </div>
            <Text size="sm" fw={400} className="text-justify">
              {text}
            </Text>
          </div>
        ))}
      </div>

      <hr className="my-4 border-t border-gray-200" />

      <form>
        <Text size="sm" className="mb-2">{t('common:delete-alert:form-instruction')}</Text>
        <Input
          autoComplete="off"
          placeholder={t('common:form-placeholder:delete') as string}
          {...register('deleteWord', {
            required: { value: true, message: t('common:form-error:required-field') },
            validate: {
              validDelete: (deleteWord) => deleteWord === t('common:form-placeholder:delete'),
            },
          })}
        />

        <div className="flex gap-2 mt-6">
          <Button
            className="min-w-0"
            color="red.9"
            variant="filled"
            disabled={!isValid}
            onClick={handleDelete}
            loading={loading}
          >
            {t('common:delete')}
          </Button>
          <Button
            variant="transparent"
            className="text-black"
            onClick={onCancel}
          >
            {t('common:cancel')}
          </Button>
        </div>
      </form>
    </Card>
  )
}
