import { useAuthUserIsUserAuthenticated, useAuthUserLoggedUser } from '../../features/auth/store/auth-user-store'
import { Page } from '../../interfaces'
import { User } from '../../models'
import pages from '../../pages'
import { Path } from '../enums'
import getUserSessionFromLocalStorage from '../utils/user/get-user-session-from-local-storage'

const usePages = (): Page[] => {
  const isUserAuthenticated = useAuthUserIsUserAuthenticated()
  let loggedUser: User | undefined = useAuthUserLoggedUser()

  if (!isUserAuthenticated) {
    const userSession = getUserSessionFromLocalStorage()
    loggedUser = userSession?.user
  }

  if (!loggedUser) {
    return pages.filter((page) => page.path === Path.Login)
  }

  return pages
}

export default usePages
