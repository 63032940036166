/* eslint-disable arrow-body-style */
import axiosInstance from '../config/axios'
import CreateContractRequest from '../requests/contracts/create-contract-request'
import CancelContractRequest from '../requests/contracts/cancel-contract-request'
import UpdateContractRequest from '../requests/contracts/update-contract-request'
import CreateContractResponse from '../responses/contracts/create-contract-response'
import GetContractsResponse from '../responses/contracts/get-contracts-response'
import UpdateContractResponse from '../responses/contracts/update-contract-response'
import GetContractsRequest from '../requests/contracts/get-contracts-request'

const CUSTOMERS_BASE_URL = '/customers'
const CONTRACTS_BASE_URL = 'contracts'

const getContracts = async (request: GetContractsRequest) => {
  return axiosInstance.get<GetContractsResponse>(`${CUSTOMERS_BASE_URL}/${request.customerId}/${CONTRACTS_BASE_URL}`)
}

const createContract = async (request: CreateContractRequest) => {
  return axiosInstance.post<CreateContractResponse>(`${CUSTOMERS_BASE_URL}/${request.customerId}/${CONTRACTS_BASE_URL}`, request)
}

const cancelContract = async (request: CancelContractRequest) => {
  return axiosInstance.post(`${CUSTOMERS_BASE_URL}/${request.customerId}/${CONTRACTS_BASE_URL}/${request.id}/cancel`, request)
}

const updateContract = async (request: UpdateContractRequest) => {
  return axiosInstance.put<UpdateContractResponse>(`${CUSTOMERS_BASE_URL}/${request.customerId}/${CONTRACTS_BASE_URL}/${request.id}`, request)
}

export default {
  getContracts,
  createContract,
  updateContract,
  cancelContract,
}
