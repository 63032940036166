import axios from 'axios'
import getUserSessionFromLocalStorage from '../../../shared/utils/user/get-user-session-from-local-storage'

const axiosInstance = axios.create({
  baseURL: `${import.meta.env.VITE_SERVER_BASE_URL || ''}/v1`,
})

axiosInstance.interceptors.request.use(
  (config) => {
    const userSession = getUserSessionFromLocalStorage()

    if (config.headers && userSession) {
      // eslint-disable-next-line no-param-reassign, @typescript-eslint/dot-notation
      config.headers['Authorization'] = `Bearer ${userSession.accessToken}`
    }

    return config
  },
)

export default axiosInstance
