import { useTranslation } from 'react-i18next'
import { Button } from '@mantine/core'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useEffect, useMemo } from 'react'
import Modal from '../../../shared/components/general/modal'
import UpsertContractAttributes from '../../../interfaces/contract/upsert-contract-attributes'
import { UseUpdateContract } from '../queries'
import ContractForm from './contract-form'
import { ExtendedContract } from '../../../models'
import upsertContractUtils from '../utils/upsert-contract-utils'

interface Props {
  opened: boolean;
  onClose: () => void;
  contract: ExtendedContract
}

export default function UpdateContractModal({ opened, onClose, contract }: Props) {
  const { t } = useTranslation('customer')
  const { id: customerId } = useParams()

  const useFormMethods = useForm<UpsertContractAttributes>({
    mode: 'onTouched',
  })

  const {
    handleSubmit, reset, formState: { isValid, isDirty }, watch,
  } = useFormMethods

  const plans = watch('plans')

  const isSubmitDisabled = useMemo(() => {
    if (!isValid || !plans) return true
    return !plans.length
  }, [isValid, plans])

  const handleUpdatePaymentAccountSuccess = () => {
    onClose()
    reset()
  }

  const { mutate, isLoading } = UseUpdateContract({ onSuccess: handleUpdatePaymentAccountSuccess })

  useEffect(() => {
    if (contract && !isDirty) {
      reset(upsertContractUtils.extendedContractToUpsertContractAttributes(contract))
    }
  }, [contract, reset, isDirty])

  return (
    <Modal size="xl" opened={opened} onClose={onClose} title={t('customer:add-contract')}>
      <FormProvider {...useFormMethods}>
        <div className="flex flex-col items-center">
          <div className="w-[calc(100%-20px)]">
            <form
              onSubmit={handleSubmit((upsertContractAttributes) => mutate({ ...upsertContractAttributes, id: contract.id, customerId: customerId! }))}
              className="flex flex-col w-full"
            >
              <ContractForm isUpdate />

              <div className="w-full h-15 bg-white bottom-0 -mb-4 sticky">
                <Button
                  className="w-50 mt-3"
                  color="violet"
                  type="submit"
                  disabled={isSubmitDisabled}
                  loading={isLoading}
                >
                  {t('common:save')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </FormProvider>
    </Modal>
  )
}
