import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { registerClarityEvent } from './config/clarity'
import { USER_SESSION_KEY } from './shared/utils/constants'

function RouteListener() {
  const location = useLocation()

  useEffect(() => {
    const localStorageUserSession = localStorage.getItem(USER_SESSION_KEY)

    if (localStorageUserSession) {
      const userSession = JSON.parse(localStorageUserSession)

      const userId = userSession.user.id || null
      const tenantId = userSession.tenantId || null

      if (userId && tenantId) {
        registerClarityEvent(userId, location.pathname, tenantId)
      }
    }
  }, [location])

  return null
}

export default RouteListener
