import { TextInput, Textarea } from '@mantine/core'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MAX_TEXT_FIELD_LENGTH, MAX_LONG_TEXT_FIELD_LENGTH } from '../../../shared/utils/constants'
import { UpsertExtendedPlanAttributes } from '../../../interfaces'
import CentsInput from '../../../shared/components/form/cents-input'

export default function PlanForm() {
  const { t } = useTranslation(['common', 'plan'])

  const {
    getValues,
    setValue,
    register,
    formState: { errors },
  } = useFormContext<UpsertExtendedPlanAttributes>()

  return (
    <div>
      <TextInput
        className="mb-4"
        label={t('plan:name') as string}
        placeholder={t('plan:name') as string}
        error={errors.name && errors.name.message}
        required
        {...register('name', {
          required: { value: true, message: t('common:form-error:required-field') },
          maxLength: { value: MAX_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
        })}
      />

      <Textarea
        className="mb-4"
        label={t('plan:description') as string}
        placeholder={t('plan:description') as string}
        autosize
        error={errors.description && errors.description.message}
        {...register('description', {
          maxLength: { value: MAX_LONG_TEXT_FIELD_LENGTH, message: t('common:form-error:max-length') },
        })}
      />

      <CentsInput
        className="w-full"
        label={`${t('plan:fixed-amount') as string}`}
        placeholder={t('common:form-placeholder:decimal-zero') as string}
        error={errors.planSettings && errors.planSettings.fixedAmount?.message}
        value={getValues('planSettings.fixedAmount')}
        onChange={(value: number) => setValue('planSettings.fixedAmount', value as number)}
      />
    </div>
  )
}
