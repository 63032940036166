import UpsertContractAttributes from '../../../interfaces/contract/upsert-contract-attributes'
import { api } from '../../../libs'
import { ExtendedContract } from '../../../models'
import upsertContractUtils from '../utils/upsert-contract-utils'

const fetchContracts = async (customerId: string): Promise<ExtendedContract[]> => {
  const getCustomersResponse = await api.contracts.getContracts({ customerId })
  return getCustomersResponse.data
}

const createContract = async (contractAttributes: UpsertContractAttributes): Promise<ExtendedContract> => {
  const createContractRequest = upsertContractUtils.upsertContractAttributesToCreateContractRequest(contractAttributes)
  const createCustomersResponse = await api.contracts.createContract(createContractRequest)
  return createCustomersResponse.data
}

const updateContract = async (contractAttributes: UpsertContractAttributes): Promise<ExtendedContract> => {
  const updateContractRequest = upsertContractUtils.upsertContractAttributesToUpdateContractRequest(contractAttributes)
  const updateCustomerResponse = await api.contracts.updateContract(updateContractRequest)
  return updateCustomerResponse.data
}

const cancelContract = async (id: string, customerId: string) => {
  const cancelContractResponse = await api.contracts.cancelContract({ id, customerId })
  return cancelContractResponse.data
}

const contractsService = {
  createContract,
  fetchContracts,
  updateContract,
  cancelContract,
}

export default contractsService
