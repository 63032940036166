import {
  Accordion, Card, Divider, Text,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import formatToCurrency from '../../../../../shared/utils/helpers/format-to-currency'
import InvoicePriceTierItems from './invoice-price-tier-items'
import { InvoiceMetricInFull } from '../../../../../models/invoice-metric'
import BillingModelPriceTierDivisionInfo from './billing-model-price-tier-division-info'

interface Props {
  metric: InvoiceMetricInFull;
  isLastIndex: boolean;
}

export default function InvoiceInFullMetricDetailItem({ metric, isLastIndex }: Props) {
  const { i18n } = useTranslation()
  const { language } = i18n

  return (
    <Accordion.Item key={metric.id} value={metric.id}>
      <Accordion.Control classNames={{
        chevron: '-mb-4',
      }}
      >
        <BillingModelPriceTierDivisionInfo metric={metric} />
        <div className="flex justify-between">
          <Text size="sm">{metric.name}</Text>
          <Text size="sm">{formatToCurrency({ price: metric.totalAmount, language })}</Text>
        </div>
      </Accordion.Control>
      <Accordion.Panel>
        <Card shadow="none" className="-mt-5 bg-gray-50">
          {metric.priceTiers.length > 0 && <InvoicePriceTierItems metric={metric} />}
        </Card>
      </Accordion.Panel>
      { !isLastIndex
          && <Divider className="mx-4" />}
    </Accordion.Item>
  )
}
