import { useState } from 'react'
import { ActionIcon, Button } from '@mantine/core'
import { FiSettings } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'
import { HiOutlineLogout } from 'react-icons/hi'
import { useAuthUserLoggedUser } from '../../auth/store/auth-user-store'
import SidebarUserInformation from './sidebar-user-information'
import handleLogout from '../../../shared/utils/user/handle-logout'
import PartialUpdateSelfUserModal from '../../user/components/partial-update-self-user-modal'

export default function SidebarFooter() {
  const { t } = useTranslation('common')
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)

  const loggedUser = useAuthUserLoggedUser()

  return (
    <div>
      {loggedUser && (
        <PartialUpdateSelfUserModal
          opened={isUpdateModalOpen}
          onClose={() => setIsUpdateModalOpen(false)}
          user={loggedUser}
        />
      )}
      <Button
        style={{
          justifyContent: 'flex-start',
        }}
        onClick={() => setIsUpdateModalOpen(true)}
        leftSection={<FiSettings size={15} />}
        fullWidth
        variant="subtle"
        className="mb-5"
        size="sm"
      >
        {t('settings')}
      </Button>
      <div className="w-full flex flex-row">
        <div className="w-10/12">
          <SidebarUserInformation />
        </div>
        <div className="w-2/12 items-center flex justify-center">
          <ActionIcon onClick={handleLogout} variant="subtle" color="gray.4">
            <HiOutlineLogout color="#868e96" size={18} />
          </ActionIcon>
        </div>
      </div>
    </div>
  )
}
