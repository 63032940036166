import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { LoginDesign, LoginFormContent } from '../features/auth'
import loginService from '../features/auth/services/login-service'
import { useAuthUserActions, useAuthUserIsUserAuthenticated } from '../features/auth/store/auth-user-store'
import { LoginAttributes } from '../interfaces'
import { Path } from '../shared/enums'

export default function Login() {
  const navigate = useNavigate()
  const isUserAuthenticated = useAuthUserIsUserAuthenticated()
  const { setLoggedUser } = useAuthUserActions()

  useEffect(() => {
    if (isUserAuthenticated) navigate(Path.Resources)
  }, [isUserAuthenticated, navigate])

  const handleLogin = async (loginAttributes: LoginAttributes) => {
    try {
      const user = await loginService.login(loginAttributes)

      setLoggedUser(user)
      navigate(Path.Resources)
    } catch { }
  }

  return (
    <div className="flex h-full">
      <div className="flex flex-col items-center h-full bg-gray-900 w-7/12">
        <div className="max-w-lg mx-9 my-auto">
          <LoginDesign />
        </div>
      </div>
      <div className="h-full bg-gray-900 w-5/12">
        <div className="flex flex-col items-center rounded-l-3xl w-full h-full bg-white">
          <div className="mx-18 my-auto">
            <LoginFormContent onLoginSubmit={handleLogin} />
          </div>
        </div>
      </div>
    </div>
  )
}
