import { UserSession } from '../../../interfaces'
import { User } from '../../../models'
import { USER_SESSION_KEY } from '../../../shared/utils/constants'

const updateUserSession = (user: User) => {
  const localStorageUserSession = localStorage.getItem(USER_SESSION_KEY)

  if (!localStorageUserSession) return

  const userSession = JSON.parse(localStorageUserSession) as UserSession

  const updatedUserSession = {
    accessToken: userSession.accessToken,
    tenantId: userSession.tenantId,
    user,
  }

  localStorage.setItem(USER_SESSION_KEY, JSON.stringify(updatedUserSession))
}

const userSessionUtils = {
  updateUserSession,
}

export default userSessionUtils
