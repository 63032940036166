import { useTranslation } from 'react-i18next'
import { Button } from '@mantine/core'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import Modal from '../../../shared/components/general/modal'
import PaymentAccountForm from './payment-account-form'
import UpsertPaymentAccountAttributes from '../../../interfaces/payment-account/payment-account-attributes'
import { UseCreatePaymentAccount } from '../queries'

interface Props {
  opened: boolean;
  onClose: () => void;
}

export default function CreatePaymentAccountModal({ opened, onClose }: Props) {
  const { t } = useTranslation('customer')
  const { id: customerId } = useParams()

  const useFormMethods = useForm<UpsertPaymentAccountAttributes>({
    mode: 'onTouched',
  })

  const {
    handleSubmit, reset, formState: { isValid },
  } = useFormMethods

  const handleCreatePaymentAccountSuccess = () => {
    onClose()
    reset()
  }

  const { mutate, isLoading } = UseCreatePaymentAccount({ onSuccess: handleCreatePaymentAccountSuccess })

  return (
    <Modal opened={opened} onClose={onClose} title={t('customer:payment-account:add-payment-account')}>
      <FormProvider {...useFormMethods}>
        <form
          onSubmit={handleSubmit((upsertPaymentAccountAttributes) => mutate({ ...upsertPaymentAccountAttributes, customerId: customerId! }))}
          className="flex flex-col px-2"
        >
          <PaymentAccountForm />
          <Button
            className="mt-7 w-50"
            color="violet"
            type="submit"
            disabled={!isValid}
            loading={isLoading}
          >
            {t('common:save')}
          </Button>
        </form>
      </FormProvider>
    </Modal>
  )
}
