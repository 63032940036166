import { modals } from '@mantine/modals'
import { useTranslation } from 'react-i18next'
import AlertCard from '../../../../shared/components/alert/alert-card'

interface Props {
  onContinue: () => void
}

const useUpdatePlanAlert = () => {
  const { t } = useTranslation()

  return ({ onContinue }: Props) => modals.open({
    children: (
      <AlertCard
        variant="warning"
        title={t('plan:editing-plan:title')}
        description={t('plan:editing-plan:alert') as string}
        onContinue={onContinue}
        onCancel={modals.closeAll}
      />
    ),
    withCloseButton: false,
  })
}

export default useUpdatePlanAlert
