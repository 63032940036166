import { useTranslation } from 'react-i18next'
import { Button } from '@mantine/core'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import Modal from '../../../shared/components/general/modal'
import PaymentAccountForm from './payment-account-form'
import UpsertPaymentAccountAttributes from '../../../interfaces/payment-account/payment-account-attributes'
import { UseUpdatePaymentAccount } from '../queries'
import { PaymentAccount } from '../../../models'
import { formatTaxIdForDisplay } from '../utils/format-tax-id'

interface Props {
  opened: boolean;
  onClose: () => void;
  paymentAccount: PaymentAccount
}

export default function UpdatePaymentAccountModal({ opened, onClose, paymentAccount }: Props) {
  const { t } = useTranslation('customer')
  const { id: customerId } = useParams()

  const useFormMethods = useForm<UpsertPaymentAccountAttributes>({
    mode: 'onTouched',
    defaultValues: {
      ...paymentAccount,
      taxId: formatTaxIdForDisplay(paymentAccount.taxId),
    },
  })

  const {
    handleSubmit, reset, formState: { isValid, isDirty },
  } = useFormMethods

  const handleUpdatePaymentAccountSuccess = () => {
    onClose()
    reset()
  }

  const { mutate, isLoading } = UseUpdatePaymentAccount({ onSuccess: handleUpdatePaymentAccountSuccess })

  useEffect(() => {
    if (paymentAccount && !isDirty) {
      reset({
        ...paymentAccount,
        taxId: formatTaxIdForDisplay(paymentAccount.taxId),
      })
    }
  }, [paymentAccount, reset, isDirty])

  return (
    <Modal opened={opened} onClose={onClose} title={t('customer:payment-account:edit-payment-account')}>
      <FormProvider {...useFormMethods}>
        <form
          onSubmit={handleSubmit((upsertPaymentAccountAttributes) => mutate({ ...upsertPaymentAccountAttributes, id: paymentAccount.id, customerId: customerId! }))}
          className="flex flex-col px-2"
        >
          <PaymentAccountForm />
          <Button
            className="mt-7 w-50"
            color="violet"
            type="submit"
            disabled={!isValid}
            loading={isLoading}
          >
            {t('common:save')}
          </Button>
        </form>
      </FormProvider>
    </Modal>
  )
}
