import { Card, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { BillingCycleStatus } from '../../enum'
import { InvoiceStatusBadge } from '../general-component/invoice-status-badge'
import formatToCurrency from '../../../../shared/utils/helpers/format-to-currency'

interface Props {
  totalAmount: number,
  status: BillingCycleStatus,
  endDate: Date,
}

export default function InvoiceDetailCard({ totalAmount, status, endDate }: Props) {
  const { t } = useTranslation(['invoice'])
  const { i18n } = useTranslation()
  const { language } = i18n

  const formatDate = (date: Date): string => dayjs(date).format('D MMM YYYY')

  return (
    <Card shadow="sm" padding="xs" radius="md">
      <InvoiceStatusBadge size="sm" variant={status} />
      <Text size="sm" fw={500} className="text-gray-700 mt-2">
        {`${t('invoice:total-value')}: `}
      </Text>
      <Text size="sm" fw={700} className="text-gray-700">
        {formatToCurrency({ price: totalAmount, language })}
      </Text>
      <Text size="xs" fw={500} className="text-gray-700">
        {`${t('invoice:end-date')}: ` }
      </Text>
      <Text size="sm" fw={700} className="text-gray-700">
        {formatDate(new Date(endDate))}
      </Text>
    </Card>
  )
}
