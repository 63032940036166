import { Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import Decimal from 'decimal.js'
import { BillingType } from '../enum'
import { PriceTier } from '../../../models'
import formatToCurrency from '../../../shared/utils/helpers/format-to-currency'

interface Props {
  from: PriceTier['from']
  to: PriceTier['to']
  price: PriceTier['price']
  packageSize: PriceTier['packageSize']
  billingType: BillingType
}

export default function PriceTierSummaryInfo({
  from, to, price, packageSize, billingType,
}: Props) {
  const { t, i18n } = useTranslation(['common', 'priceTier'])
  const { language } = i18n

  const commonTextProps = { fz: 'sm', fw: 400, className: 'text-gray-600' }

  let fromToText
  if ((!from || from === 0) && to) {
    fromToText = `${t('priceTier:summary:until')} ${to}`
  } else if (from > 0 && to) {
    fromToText = `${t('priceTier:from')} ${from} ${t('priceTier:to')} ${to}`
  } else if (from > 0 && !to) {
    fromToText = `${t('priceTier:summary:from')} ${from}`
  } else {
    fromToText = `${t('priceTier:summary:complete')}`
  }

  const billingTexts = {
    [BillingType.Flat]: t('priceTier:summary:flat'),
    [BillingType.Unit]: t('priceTier:summary:unit'),
    [BillingType.Package]: packageSize ? `${t('priceTier:summary:package')} ${packageSize} ${t('priceTier:summary:unit')}` : null,
  }

  return (
    <div>
      <div className="flex flex-row w-full justify-between">
        {fromToText && <Text {...commonTextProps}>{fromToText}</Text>}
        <Text fz="sm" fw={500}>
          {formatToCurrency({ price: new Decimal(price).times(100).toNumber(), language })}
        </Text>
      </div>
      <div className="flex flex-row w-full justify-end">
        {billingTexts[billingType] && <Text {...commonTextProps}>{billingTexts[billingType]}</Text>}
      </div>
    </div>
  )
}
