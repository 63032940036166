import { Card, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import InvoicePlan from '../../../../../models/invoice-plan'
import InvoiceMetricsDetailAccordion from './invoice-metrics-detail-accordion'
import formatToCurrency from '../../../../../shared/utils/helpers/format-to-currency'

interface Props {
  plan: InvoicePlan
}

export default function InvoicePlanDetailCard({ plan }: Props) {
  const { t, i18n } = useTranslation(['invoice', 'common'])
  const { language } = i18n

  return (
    <Card shadow="sm" padding="lg" radius="md" className="flex justify-between items-center">
      <div className="flex flex-col gap-4 w-full">
        <Text size="lg" fw={700} className="text-black">
          {`${t('invoice:detail:plan')} ${plan.name}`}
        </Text>

        <div className="flex justify-between">
          <Text size="sm">{t('common:fixed-amount')}</Text>
          <Text size="sm">{formatToCurrency({ price: plan.planSettings.fixedAmount, language })}</Text>
        </div>

        {plan.metrics.length > 0 && (
          <>
            <hr className="my-1 border-t border-gray-400" />
            <div className="flex justify-between">
              <Text size="md" fw={500} className="text-black">
                {t('invoice:detail:resource')}
              </Text>
              <Text size="mf" fw={500} className="text-black">
                {t('invoice:detail:total')}
              </Text>
            </div>
          </>
        )}

        <InvoiceMetricsDetailAccordion metrics={plan.metrics} />

      </div>
    </Card>
  )
}
