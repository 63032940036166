import {
  Card, Text,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Customer } from '../../../../models'
import { AvatarInitials } from '../../../../shared/components/user/avatar-initials'

interface Props {
  customer: Customer
}

export default function InvoiceDetailCustomerCard({ customer }: Props) {
  const { t } = useTranslation(['invoice'])

  return (
    <Card shadow="xs" padding="xs" radius="md" className="flex flex-col">
      <Text size="sm" fw={500} className="text-gray-700 mb-2">
        {t('invoice:detail-customer')}
      </Text>
      <div className="flex flex-row items-center gap-3">
        <AvatarInitials size="md" text={customer.name} />
        <Text size="sm">{customer.name}</Text>
      </div>
    </Card>
  )
}
