import { ReactElement } from 'react'
import {
  Button, Card, Text,
} from '@mantine/core'
import { AiOutlineWarning } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'

interface Props {
  variant: 'warning' | 'danger';
  title: string;
  description: string | ReactElement;
  onContinue?: () => void;
  onCancel?: () => void;
  onDismiss?: () => void;
}

export default function AlertCard({
  variant, title, description, onContinue, onCancel, onDismiss,
}: Props) {
  const { t } = useTranslation(['common'])
  const variantColor = variant === 'warning' ? 'yellow' : 'red'

  return (
    <Card padding="xs" radius="md">
      <div className="flex flex-row items-center gap-2">
        <Button
          radius="md"
          variant="light"
          color={variantColor}
          className="px-2 cursor-default"
        >
          <AiOutlineWarning size={20} />
        </Button>
        <Text size="md" fw={600}>
          {title}
        </Text>
      </div>

      <Text size="sm" fw={400} className="my-4 text-justify">
        {description}
      </Text>

      <div className="flex gap-2 mt-3 mb-4">
        {onContinue && (
          <Button
            className="min-w-0"
            variant="filled"
            color={variantColor}
            onClick={onContinue}
          >
            {t('common:continue')}
          </Button>
        )}
        {onDismiss && (
          <Button
            className="min-w-0"
            variant="filled"
            color={variantColor}
            onClick={onDismiss}
          >
            {t('common:dismiss')}
          </Button>
        )}
        {onCancel && (
          <Button
            variant="transparent"
            className="text-black"
            onClick={onCancel}
          >
            {t('common:cancel')}
          </Button>
        )}
      </div>
    </Card>
  )
}
