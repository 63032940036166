import i18n from '../../../config/i18n'
import UpsertInvoiceAdditionalItemAttributes from '../../../interfaces/invoice/upsert-invoice-additional-item-attributes'
import { api } from '../../../libs'
import GetInvoiceRequest from '../../../libs/api/requests/invoices/get-invoice-request'
import { Invoice } from '../../../models'
import ExtendedInvoice from '../../../models/extended-invoice'
import fileHelper from '../../../shared/utils/helpers/file-helper'
import { upsertInvoiceAdditionalItemAttributesToRequest } from '../utils/upsert-invoice-utils'

const fetchInvoices = async (): Promise<Invoice[]> => {
  const getInvoicesResponse = await api.invoices.getInvoices()
  return getInvoicesResponse.data
}

const fetchInvoice = async (getInvoiceAttributes: GetInvoiceRequest): Promise<ExtendedInvoice> => {
  const getInvoiceResponse = await api.invoices.getInvoice(getInvoiceAttributes)
  return getInvoiceResponse.data
}

const downloadInvoiceDocument = async (invoiceId: string) => {
  const getInvoiceDocumentResponse = await api.invoices.getDocument({ id: invoiceId })

  const fileName = `${i18n.t('invoice')}_${invoiceId}`

  fileHelper.savePdfFile(getInvoiceDocumentResponse?.data, fileName)
}

const updateInvoiceAdditionalItems = async (
  invoiceId: string,
  additionalItems: UpsertInvoiceAdditionalItemAttributes[],
  currentInvoiceAdditionalItems: UpsertInvoiceAdditionalItemAttributes[],
): Promise<ExtendedInvoice> => {
  const updateInvoiceAdditionalItemsRequest = upsertInvoiceAdditionalItemAttributesToRequest(invoiceId, additionalItems, currentInvoiceAdditionalItems)

  const updateInvoiceAdditionalItemsResponse = await api.invoices.updateAdditionalItems(updateInvoiceAdditionalItemsRequest)

  return updateInvoiceAdditionalItemsResponse?.data
}

const InvoicesService = {
  fetchInvoices,
  fetchInvoice,
  downloadInvoiceDocument,
  updateInvoiceAdditionalItems,
}

export default InvoicesService
