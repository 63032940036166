import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ScrollArea, Text } from '@mantine/core'
import { useInvoiceQuery } from '../../queries'
import InvoiceDetailHeader from './invoice-detail-header'
import InvoicePlansDetail from './plan-detail/invoice-plans-detail'
import FetchInvoiceSkeleton from '../general-component/fetch-invoice-skeleton'
import ManageInvoiceAdditionalItemsModal from './additional-item/manage-invoice-additional-items-modal'
import InvoiceDetailSideContent from './invoice-detail-side-content'
import { extendedInvoiceAdditionalItemsToUpsertInvoiceAdditionalItems } from '../../utils/upsert-invoice-utils'
import InvoiceAdditionalItemsDetail from './additional-item/invoice-additional-items-detail'

export default function InvoiceDetailContent() {
  const { t } = useTranslation(['invoice'])

  const { id } = useParams()
  const { data: invoice, isLoading: isQueryLoading } = useInvoiceQuery(id)

  const [isManageInvoiceModalOpened, setIsManageInvoiceModalOpened] = useState(false)

  if (isQueryLoading || !invoice) {
    return <FetchInvoiceSkeleton />
  }

  const invoiceWithoutPlans = invoice?.plans.length === 0

  const upsertedAdditionalItems = extendedInvoiceAdditionalItemsToUpsertInvoiceAdditionalItems(invoice.additionalItems)

  const handleOpenManageInvoiceModal = () => {
    setIsManageInvoiceModalOpened(true)
  }

  return (
    <div>
      <ManageInvoiceAdditionalItemsModal
        invoiceId={invoice.id}
        opened={isManageInvoiceModalOpened}
        onClose={() => setIsManageInvoiceModalOpened(false)}
        additionalItems={upsertedAdditionalItems}
      />

      <InvoiceDetailHeader invoiceId={invoice.id} customerName={invoice.customer.name} endDate={invoice.endDate} />

      <div className="my-4">
        {!invoiceWithoutPlans && (
        <Text size="lg" fw={700} className="text-black">
          {t('invoice:detail-label')}
        </Text>
        )}
      </div>

      <div className="flex w-full">
        <div className="w-9/12">
          <div className="h-[calc(100vh-300px)]">
            <ScrollArea.Autosize className="h-full mr-2" offsetScrollbars>
              <InvoicePlansDetail plans={invoice.plans} />
              <InvoiceAdditionalItemsDetail additionalItems={upsertedAdditionalItems} />
            </ScrollArea.Autosize>
          </div>
        </div>

        <InvoiceDetailSideContent
          invoice={invoice}
          handleOpenManageInvoiceModal={handleOpenManageInvoiceModal}
        />
      </div>
    </div>
  )
}
