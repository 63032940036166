import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import paymentAccountsService from './services/payment-accounts-service'
import MutationParams from '../../interfaces/queries/mutation-params'
import { PaymentAccount } from '../../models'
import { QueryParams } from '../../interfaces'
import queryClient from '../../config/query-client'
import i18n from '../../config/i18n'

const paymentAccountsKeys = {
  all: [{ scope: 'paymentAccounts' }] as const,
  lists: () => [{ ...paymentAccountsKeys.all[0], entity: 'list' }] as const,
  list: (customerId: string) => [...paymentAccountsKeys.lists(), { customerId }] as const,
  single: (id: string) => [{ ...paymentAccountsKeys.all[0], entity: 'single', id }] as const,
}

export const UsePaymentAccountsQuery = <ModelSchema = PaymentAccount[]>(customerId?: string, params?: QueryParams<PaymentAccount[], ModelSchema>) => useQuery({
  queryKey: paymentAccountsKeys.list(customerId!),
  queryFn: () => paymentAccountsService.fetchPaymentAccounts(customerId!),
  select: params?.select,
  enabled: !!customerId,
})

export const UseCreatePaymentAccount = (params?: MutationParams<PaymentAccount>) => useMutation({
  mutationFn: paymentAccountsService.createPaymentAccount,
  onSuccess: (newPaymentAccount) => {
    queryClient.setQueryData<PaymentAccount[]>(paymentAccountsKeys.list(newPaymentAccount.customerId), (paymentAccounts) => {
      if (!paymentAccounts) return [newPaymentAccount]

      return [newPaymentAccount, ...paymentAccounts]
    })

    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess(newPaymentAccount)
  },
})

export const UseUpdatePaymentAccount = (params?: MutationParams<PaymentAccount>) => useMutation({
  mutationFn: paymentAccountsService.updatePaymentAccount,
  onSuccess: (updatedPaymentAccount) => {
    queryClient.setQueryData<PaymentAccount[]>(paymentAccountsKeys.list(updatedPaymentAccount.customerId), (paymentAccounts) => {
      if (!paymentAccounts) return []

      return paymentAccounts.map((paymentAccount) => (paymentAccount.id === updatedPaymentAccount.id ? updatedPaymentAccount : paymentAccount))
    })

    queryClient.setQueryData<PaymentAccount>(paymentAccountsKeys.single(updatedPaymentAccount.id), updatedPaymentAccount)

    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess(updatedPaymentAccount)
  },
})

export const UseDeletePaymentAccount = (customerId: string, params?: MutationParams<void>) => useMutation({
  mutationFn: (id: string) => paymentAccountsService.deletePaymentAccount(id, customerId),
  onSuccess: (_data, id) => {
    queryClient.setQueryData<PaymentAccount[]>(paymentAccountsKeys.lists(), (paymentAccounts) => {
      if (!paymentAccounts) return []

      return paymentAccounts.filter((paymentAccount) => (paymentAccount.id !== id))
    })
    toast.success(i18n.t('messages:success.default'))
    params?.onSuccess()
  },
  onError: params?.onError,
})
