import { Invoice } from '../../../models'
import { InvoiceFilter } from '../interfaces/invoice-filter'

const getDateRangeMatch = (invoice: Invoice, dateRange: [Date | null, Date | null]) => {
  const [startFilterDate, endFilterDate] = dateRange
  const endInvoiceDate = new Date(invoice.endDate)

  if (!startFilterDate && !endFilterDate) return true
  if (startFilterDate && !endFilterDate) return endInvoiceDate >= startFilterDate
  if (!startFilterDate && endFilterDate) return endInvoiceDate <= endFilterDate
  if (startFilterDate && endFilterDate) return endInvoiceDate >= startFilterDate && endInvoiceDate <= endFilterDate

  return true
}

const getFilteredInvoices = (invoices: Invoice[], filter: InvoiceFilter) => {
  const { customers, status, dateRange } = filter

  return invoices.filter((invoice) => {
    const isCustomerMatch = customers.length === 0 || customers.includes(invoice.customer.id)
    const isStatusMatch = status.length === 0 || status.includes(invoice.status)
    const isDateRangeMatch = getDateRangeMatch(invoice, dateRange)
    return isCustomerMatch && isStatusMatch && isDateRangeMatch
  })
}

export { getFilteredInvoices }
