import axiosInstance from '../config/axios'
import GetInvoiceDocumentRequest from '../requests/invoices/get-invoice-document-request'
import GetInvoiceRequest from '../requests/invoices/get-invoice-request'
import UpdateInvoiceAdditionalItemsRequest from '../requests/invoices/update-invoice-additional-items-request'
import GetInvoiceDocumentResponse from '../responses/invoices/get-invoice-document-response'
import GetInvoiceResponse from '../responses/invoices/get-invoice-response'
import GetInvoicesResponse from '../responses/invoices/get-invoices-response'
import UpdateInvoiceAdditionalItemsResponse from '../responses/invoices/update-invoice-additional-items-response'

const INVOICE_BASE_URL = '/invoices'

const getInvoice = async (request: GetInvoiceRequest) => (axiosInstance.get<GetInvoiceResponse>(`${INVOICE_BASE_URL}/${request.id}`))

const getInvoices = async () => (axiosInstance.get<GetInvoicesResponse>(INVOICE_BASE_URL))

const getDocument = async (request: GetInvoiceDocumentRequest) => (axiosInstance.get<GetInvoiceDocumentResponse>(
  `${INVOICE_BASE_URL}/${request.id}/document/download`,
  {
    responseType: 'blob',
  },
))

// eslint-disable-next-line arrow-body-style
const updateAdditionalItems = async (request: UpdateInvoiceAdditionalItemsRequest) => {
  return (axiosInstance.put<UpdateInvoiceAdditionalItemsResponse>(`${INVOICE_BASE_URL}/${request.invoiceId}/additional-items`, request.additionalItems))
}

export default {
  getInvoice,
  getInvoices,
  getDocument,
  updateAdditionalItems,
}
