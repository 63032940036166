const SPACING_MULTIPLIER = 4

const getSpacing = () => {
  const spacingOptions = 500
  const spacingObject: Record<number, string> = {}

  for (let optionIndex = 0; optionIndex < spacingOptions;) {
    const spacingSize = optionIndex * SPACING_MULTIPLIER
    spacingObject[optionIndex] = `${spacingSize}px`
    optionIndex += 1 // ou optionIndex += SPACING_MULTIPLIER, dependendo da lógica desejada
  }

  return spacingObject
}

const themeConfiguration = {
  colors: {
    gray: {
      900: '#212529',
      800: '#343a40',
      700: '#495057',
      600: '#868e96',
      500: '#adb5bd',
      400: '#ced4da',
      300: '#dee2e6',
      200: '#e9ecef',
      100: '#f1f3f5',
      50: '#f8f9fa',
    },
    red: {
      900: '#c92a2a',
      800: '#e03131',
      700: '#f03e3e',
      600: '#fa5252',
      500: '#ff6b6b',
      400: '#ff8787',
      300: '#ffa8a8',
      200: '#ffc9c9',
      100: '#ffe3e3',
      50: '#fff5f5',
    },
    green: {
      900: '#2b8a3e',
      800: '#2f9e44',
      700: '#37b24d',
      600: '#40c057',
      500: '#51cf66',
      400: '#69db7c',
      300: '#8ce99a',
      200: '#b2f2bb',
      100: '#d3f9d8',
      50: '#ebfbee',
    },
    yellow: {
      900: '#e67700',
      800: '#f08c00',
      700: '#f59f00',
      600: '#fab005',
      500: '#fcc419',
      400: '#ffd43b',
      300: '#ffe066',
      200: '#ffec99',
      100: '#fff3bf',
      50: '#fff9db',
    },
    violet: {
      900: '#5f3dc4',
      800: '#6741d9',
      700: '#7048e8',
      600: '#7950f2',
      500: '#845ef7',
      400: '#9775fa',
      300: '#b197fc',
      200: '#d0bfff',
      100: '#e5dbff',
      50: '#f3f0ff',
    },
    white: '#ffffff',
    black: '#000000',
    primary: '#7048e8', // violet-700
    secondary: '#9775fa', // violet-400
  },
  font: {
    weight: {
      100: '100',
      200: '200',
      300: '300',
      400: '400',
      500: '500',
      600: '600',
      700: '700',
      800: '800',
      900: '900',
    },
    size: {
      h1: '34px',
      h2: '26px',
      h3: '22px',
      h4: '18px',
      h5: '16px',
      h6: '14px',
      xl: '20px',
      lg: '18px',
      md: '16px',
      sm: '14px',
      xs: '12px',
    },
    lineHeight: {
      h1: '48px',
      h2: '40px',
      h3: '32px',
      h4: '24px',
      h5: '24px',
      h6: '24px',
      xl: '32px',
      lg: '24px',
      md: '24px',
      sm: '24px',
      xs: '16px',
    },
  },
  spacingMultiplier: SPACING_MULTIPLIER,
  spacing: getSpacing(),
}

export default themeConfiguration
