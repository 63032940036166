import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TableColumn } from '../../../../interfaces'
import tableUserUtils from '../../utils/table-invoice-utils'
import { CoreTable } from '../../../../shared/components/table'
import { useFilteredInvoicesQuery } from '../../queries'
import { TableDateCell } from '../../../../shared/components/table/table-date-cell'
import InvoiceViewModel from '../../interfaces/invoice-view-model'
import { TableInvoiceAmountCell } from './table-invoice-amount-cell'
import { Path } from '../../../../shared/enums'
import { TableInvoiceStatusCell } from './table-invoice-status-cell'

export default function TableInvoices() {
  const { t } = useTranslation(['invoice', 'common'])
  const navigate = useNavigate()

  const {
    data: invoices,
    isLoading,
    isFetching,
  } = useFilteredInvoicesQuery()

  const invoicesToViewModel: InvoiceViewModel[] = invoices
    ? invoices.map((invoice) => ({
      ...invoice,
      customerName: invoice.customer.name,
      displayAmount: {
        amount: invoice.totalAmount,
        status: invoice.status,
      },
    })) : []

  const tableUserColumns: TableColumn<InvoiceViewModel>[] = [
    {
      label: t('invoice:id'),
      key: 'id',
      columnKey: 'id',
      widthPercentage: 15,
    },
    {
      label: t('invoice:client'),
      key: 'customerName',
      columnKey: 'customerId',
      widthPercentage: 28,
    },
    {
      label: t('invoice:billing_date'),
      key: 'endDate',
      columnKey: 'endDate',
      widthPercentage: 15,
      Component: TableDateCell,
    },
    {
      label: t('invoice:value'),
      key: 'displayAmount',
      columnKey: 'displayAmount',
      widthPercentage: 20,
      Component: TableInvoiceAmountCell,
    },
    {
      label: t('invoice:status'),
      key: 'status',
      columnKey: 'status',
      widthPercentage: 15,
      Component: TableInvoiceStatusCell,
    },
  ]

  const handleInvoiceDetail = (invoice: InvoiceViewModel) => {
    navigate(Path.InvoiceDetail.replace(':id', invoice.id))
  }

  return (
    <CoreTable<InvoiceViewModel>
      data={invoicesToViewModel || []}
      indexKey={tableUserUtils.indexKey}
      columns={tableUserColumns}
      isFetching={isFetching}
      isLoading={isLoading}
      pageConfiguration={{
        pageVariation: 1,
      }}
      rowAction={handleInvoiceDetail}
    />
  )
}
