import { useTranslation } from 'react-i18next'
import { BillingCycleStatus } from '../enum'

const useInvoiceStatusOptions = () => {
  const { t } = useTranslation('invoice')

  return Object.values(BillingCycleStatus)
    .map((status) => ({
      label: t(`invoice:status-label:${status}`),
      value: status,
    }))
    .filter(({ value }) => value !== BillingCycleStatus.Paid)
}

export { useInvoiceStatusOptions }
