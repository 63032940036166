import { useFormContext } from 'react-hook-form'
import {
  Text, Card,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { BiTrashAlt } from 'react-icons/bi'
import PriceTierFromToInput from './price-tier-from-to-input'
import { UpsertExtendedPlanAttributes } from '../../../interfaces'
import DecimalInput from '../../../shared/components/form/decimal-input'

interface Props {
  metricIndex: number,
  index: number,
  remove: (index: number) => void,
}

export default function BillingUnitForm({ metricIndex, index, remove }: Props) {
  const { t } = useTranslation(['common', 'priceTier'])

  const {
    setValue, getValues, formState: { errors },
  } = useFormContext<UpsertExtendedPlanAttributes>()

  return (
    <Card withBorder padding="xs" radius="md">
      <div className="flex mb-4 justify-between">
        <div className="flex flex-row">
          <Text className="text-gray-700" size="sm">
            {`${t('priceTier:billing-type')}:`}
          </Text>
          <Text className="text-gray-800 ml-1" fw={600} size="sm">
            {t('priceTier:billing-type-unit')}
          </Text>
        </div>
        <BiTrashAlt
          className="cursor-pointer"
          onClick={() => remove(index)}
          size={22}
        />
      </div>
      <div className="flex flex-wrap flex-row gap-2 items-center">
        <PriceTierFromToInput metricIndex={metricIndex} index={index} />
        <div className="flex flex-row gap-2 items-center">
          <Text inline className="text-gray-700" size="sm">{`${t('priceTier:billing-type-unit-label')}:`}</Text>
          <DecimalInput
            className="w-40"
            placeholder={t('common:form-placeholder:zero') as string}
            error={errors.metrics?.[metricIndex]?.priceTiers?.[index]?.price && errors.metrics?.[metricIndex]?.priceTiers?.[index]?.message}
            value={getValues(`metrics.${metricIndex}.priceTiers.${index}.price`)}
            onChange={(value: string) => setValue(`metrics.${metricIndex}.priceTiers.${index}.price`, value)}
            required
          />
        </div>
      </div>
    </Card>
  )
}
