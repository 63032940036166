import { Text, Tooltip } from '@mantine/core'
import { AvatarInitials } from '../../../shared/components/user/avatar-initials'
import { useAuthUserLoggedUser } from '../../auth/store/auth-user-store'
import stringHelper from '../../../shared/utils/helpers/string-helper'

export default function SidebarUserInformation() {
  const loggedUser = useAuthUserLoggedUser()

  const { text: name, isTextLimited: isNameLimited } = stringHelper.limitTextDetailed(loggedUser.name, 13)
  // const { text: email, isTextLimited: isEmailLimited } = stringHelper.limitTextDetailed(loggedUser.email, 15)

  return (
    <div className="w-full flex items-center">
      <div className="w-4/12 flex items-center">
        <AvatarInitials text={loggedUser.name} size="2rem" />
      </div>
      <div className="w-8/12">
        {isNameLimited ? (
          <Tooltip label={loggedUser.name}>
            <Text size="sm" title={name} className="w-full truncate text-gray-700">{name}</Text>
          </Tooltip>
        ) : (
          <Text size="sm" title={loggedUser.name} className="w-full truncate text-gray-700">{loggedUser.name}</Text>
        )}

        {/* {isEmailLimited ? (
          <Tooltip label={loggedUser.email}>
            <Text size="xs" title={email} className="w-full truncate text-gray-600">{email}</Text>
          </Tooltip>
        ) : (
          <Text size="xs" title={loggedUser.email} className="w-full truncate text-gray-600">{loggedUser.email}</Text>
        )} */}
      </div>
    </div>
  )
}
