import { Accordion } from '@mantine/core'
import PriceTierSummaryInfo from './price-tier-summary-info'
import usePriceTierSummaryTitle from '../utils/use-price-tier-summary-title'
import { UpsertPriceTierAttributes } from '../../../interfaces'

interface Props {
  priceTiers: UpsertPriceTierAttributes[]
}

export default function PriceTierSummary({ priceTiers }: Props) {
  const priceTierSummaryTitle = usePriceTierSummaryTitle()
  return (
    <div>
      {priceTiers.map((priceTier) => (
        <div key={priceTier.renderId || priceTier.id}>
          <Accordion className="-mx-5" variant="filled">
            <Accordion.Item value="price-tier">
              <Accordion.Control className="text-sm" fw={600}>{priceTierSummaryTitle(priceTier.billingType)}</Accordion.Control>
              <Accordion.Panel>
                <div className="flex flex-col w-full">
                  <PriceTierSummaryInfo
                    from={priceTier.from}
                    to={priceTier.to}
                    price={priceTier.price}
                    packageSize={priceTier.packageSize}
                    billingType={priceTier.billingType}
                  />
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </div>
      ))}
    </div>
  )
}
